import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Eventregister from "../../Media-module/Media-layout-page/Event-Page/Eventregister";
import apiService from "../../../api/apiService";
import { MdArrowDropDownCircle } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

const HomeEventSection = () => {
  const { navigator, setPageLoading, fullScreen } = useContext(DataContext);
  useDidMountEffect(() => {
    departmentdropdownList();
  }, []);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [opens, setOpens] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [week, setWeek] = useState(0);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [screenSize, setScreenSize] = useState(4);
  const [depId, setDepId] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState(true);

  useDidMountEffect(() => {
    listApi();
  }, [page, upcomingEvents, depId]);
  const [eventList, setEventList] = useState([]);

  const listApi = () => {
    setEventList([]);
    setPageLoading(true);
    const today = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(today.getFullYear() + 1);

    // Format the dates as YYYY-MM-DD
    const fromDate = today.toISOString().split("T")[0];
    const toDate = nextYear.toISOString().split("T")[0];
    var req = {
      // categoryId: categoryId,
      listSize: 3,
      pageNumber: page,
      departmentId: depId,
      upcomingEvents: upcomingEvents,
    };
    debugger;
    apiService(`event/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data && result.data.event) {
          setEventList(result.data.event);
          setTotalPage(result.data.count);
        }
      })
      .catch((err) => {});
  };
  const handleWeekChange = (value) => {
    setWeek(value);
  };
  const [eventId, setEventId] = useState(0);
  const handleRegister = (val, url) => {
    // var token = localStorage.getItem("jwttoken");
    // if (token === "") {
    //   navigator("/login");
    // } else if (token === null) {
    //   navigator("/login");
    // } else {
    //   setEventId(val);
    //   setOpens(true);
    // }
    // debugger
    debugger;
    apiService(`event/view/count?id=${val}`, "", "unauthput")
      .then((result) => {
        if (result && result.data && result.data.responseStatus === "Success") {
        }
      })
      .catch((err) => {});
    debugger;
    if (url !== undefined) {
      debugger;
      window.open(url, "_blank");
    }
  };
  const handleReportClose = () => {
    setOpens(false);
  };
  const handleChange = (value) => {
    setPage(value);
  };
  const [departmentList, setDepartmentList] = useState([]);
  const departmentdropdownList = () => {
    apiService(`home/department/list`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data) {
              var values = result.data;
              setDepartmentList(values);
            }
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    size();
  }, []);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };
  const handleSelectDepartment = (value) => {
    debugger;
    if (value !== null) {
      setDepId(value.id);
    } else {
      setDepId("");
    }
  };
  const handleTabClick = (tab, status) => {
    setActiveTab(tab);
    setUpcomingEvents(status);
  };

  return (
    <section className="event-section">
      <div className="whole-event-sections">
        <div className="event-header mb-5">
          <h4 style={{ color: "#da4589" }}>
            <b>Events</b>
          </h4>
        </div>
        <div className="home-event-title">
          {/* <h4 className="home-coming">
            <b>Events</b>
          </h4> */}
          <div className="event-filters">
            {/* <div className="filter-btns c-pointer">
              <span>
                <img
                  src={"/images/filtericn.png"}
                  alt="filtericn"
                  onClick={() => setShowForm(!showForm)}
                />
              </span>
              <p className="filter-text" onClick={() => setShowForm(!showForm)}>
                Filters
              </p>
            </div> */}
            <div className="mb-3">
              {/* {showForm && ( */}
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="tabs-container">
                    <button
                      className={`tab ${
                        activeTab === "upcoming" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("upcoming", true)}
                    >
                      Upcoming
                    </button>
                    <button
                      className={`tab ${
                        activeTab === "previous" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("previous", false)}
                    >
                      Previous
                    </button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Autocomplete
                    disablePortal
                    options={departmentList}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) =>
                      handleSelectDepartment(newValue)
                    }
                    sx={{
                      "& .MuiAutocomplete-clearIndicator": {
                        color: "white",
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        color: "#646568",
                      },

                      "& .MuiInputBase-input": {
                        color: "white",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Department"
                        placeholder="Select Department"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                            "&:hover fieldset": {
                              borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "white",
                          },
                        }}
                      />
                    )}
                    fullWidth
                    margin="normal"
                  />

                  <div>
                    {/* <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handlepageChange}
                    /> */}
                  </div>
                </Grid>
              </Grid>
              {/* )} */}
            </div>
          </div>
          {eventList.length > 0 ? (
            <>
              <Swiper
                slidesPerView={screenSize}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                  renderBullet: function (index, className) {
                    return `<span class="${className}" style="background-color: orange;"></span>`;
                  },
                }}
                modules={[Pagination]}
                className="mySwiper"
                style={{ paddingBottom: "50px" }}
              >
                {eventList &&
                  eventList.map((item, index) => (
                    <SwiperSlide>
                      {/* <div className="event-list">
                        <div
                          class="card"
                          style={{
                            width: "18rem",
                            minHeight: "380px",
                            borderRadius: "10px",
                            padding: "15px 0px",
                          }}
                          key={index}
                        >
                          <div className="event-img">
                            <img
                              src={
                                item.imageName
                                  ? item.imageName
                                  : "/images/oneTn/white-image.png"
                              }
                              class="event-home-img-top"
                              alt=""
                            />
                            <div className="img-top">
                              <div className="buttonStyle">
                                {item.category ? item.category : "-"}
                              </div>
                            </div>
                          </div>
                          <div
                            class="card-body"
                            style={{ padding: "10px", position: "relative" }}
                          >
                            <div className="event-details">
                              <div>
                                <h5>{item.title ? item.title : "-"}</h5>

                                <span className="event-location">
                                  <img
                                    src="/images/oneTn/locations.png"
                                    alt=""
                                    className="mr-2"
                                  />
                                  {item.location ? item.location : "-"}
                                </span>
                                <h6 className="event-time mt-2">
                                  <img
                                    src="/images/oneTn/calander.png"
                                    alt=""
                                    className="mr-2"
                                  />
                                  {item.startDay ? item.startDay : ""} ,{" "}
                                  {item.endDay ? item.endDay : ""}{" "}
                                  {item.startMonth ? item.startMonth : ""}{" "}
                                  {item.year ? item.year : ""}
                                </h6>
                              </div>
                              <div className="reg-fees2 mt-2">
                                <span className="event-free">
                                  ₹ {item.amount}/-{" "}
                                  <span className="event-frees-spans">
                                    onwards
                                  </span>
                                </span>

                                <div>
                                  <button
                                    className="reg-btn"
                                    onClick={() =>
                                      handleRegister(item.id, item.url)
                                    }
                                  >
                                    Book Now
                                  </button>
                                </div>                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="event-list">
                        <div
                          class="card"
                          style={{
                            width: "18rem",
                            minHeight: "380px",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="event-img">
                            <img
                              src={
                                item.imageName
                                  ? item.imageName
                                  : "/images/oneTn/white-image.png"
                              }
                              class="card-img-top"
                              alt=""
                            />
                            {item.category && (
                              <div className="img-top">
                                <span>
                                  {item.category ? item.category : ""}
                                </span>
                              </div>
                            )}

                            <div className="event-time-top">
                              <div className="home-event-days">
                                {item.startDay && (
                                  <>
                                    <div className="time-bg">
                                      <h6 className="time-color">
                                        {item.startDay}
                                      </h6>
                                      <span>{item.startMonth}</span>
                                    </div>
                                  </>
                                )}
                                {item.endDay && (
                                  <>
                                    <div className="time-bg ml-2">
                                      <h6 className="time-color">
                                        {item.endDay}
                                      </h6>
                                      <span>{item.endMonth}</span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            class="card-body"
                            style={{ padding: "10px", position: "relative" }}
                          >
                            <div className="event-details">
                              <h5>{item.title}</h5>
                              <h6 className="event-time mt-2">
                                <img
                                  src="/images/clock-icon.png"
                                  alt=""
                                  className="mr-2"
                                />
                                {item.formattedTime}
                                {/* - <span className="event-free">({item.meetSpace})</span> */}
                              </h6>
                              {item.location ? (
                                <span className="event-location">
                                  <img
                                    src="/images/location-icon.png"
                                    alt=""
                                    className="mr-2"
                                  />
                                  {item.location}
                                </span>
                              ) : (
                                // <span className="event-location">&nbsp;</span>
                                <span className="event-location">
                                  <img
                                    src="/images/online-symbal.png"
                                    alt=""
                                    className="mr-2"
                                  />
                                  Online
                                </span>
                              )}

                              <div className="reg-fees2 mt-2">
                                {item.paid === false ? (
                                  <span className="event-free">Free</span>
                                ) : (
                                  <span className="event-free">
                                    ₹{item.amount}
                                  </span>
                                )}
                                {item.showRegisterButton === true && (
                                  <div>
                                    <button
                                      className="reg-btn"
                                      onClick={() =>
                                        handleRegister(item.id, item.url)
                                      }
                                    >
                                      Register
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </>
          ) : (
            <>
              <h3
                style={{
                  color: "#da4589",
                  textAlign: "center",
                  padding: "70px 0px",
                }}
              >
                No Events Found
              </h3>
            </>
          )}
        </div>
      </div>
      <Dialog fullScreen={fullScreen} maxWidth={"lg"} open={opens}>
        <DialogContent className="dialog-modal">
          <DialogContentText id="alert-dialog-description">
            <div>
              <span
                style={{ color: "#2260FF", cursor: "pointer" }}
                onClick={handleReportClose}
              >
                <ArrowBackIcon /> Back
              </span>
            </div>
            <Eventregister
              categoryId={eventId}
              setOpens={setOpens}
              via={"home"}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default HomeEventSection;
