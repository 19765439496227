import React, { useContext, useEffect, useState } from "react";
import {Grid,Button,Card,CardMedia,CardContent,FormHelperText,} from "@mui/material";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { BiSearchAlt } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import DataContext from "../../../../context/DataContext";
import EditIcon from "@mui/icons-material/Edit";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Controller, useForm } from "react-hook-form";

const InvestorProfilePage = () => {
  const { navigator, validateNumberonly, setPageLoading, VisuallyHiddenInput, validatePhoneNumber, fullScreen, modalstyle,
    linkedInPattern, validateEmail,getApi,investorpara, formData, handleOpenEdit, editBtn,setEditBtn, handleEditBtn, editKey, setEditKey } = useContext(DataContext);

    const {handleSubmit,control,setValue,getValues,reset,clearErrors,formState: { errors },} = useForm({ values: formData });
  useEffect(() => {
    size();
  }, []);
  useDidMountEffect(() => {
    getApi();
  }, []);
  const [screenSize, setScreenSize] = useState(3);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(3);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(3);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(2);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(2);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(2);
    } else {
      setScreenSize(1);
    }
  };
  const onSubmit = (data) => {
    apiService("userprofile/save", data, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          if (editKey === "") {
            setEditBtn(false);
          } else {
            setEditKey("");
          }
          getApi();
        }
       
      })
      .catch((err) => {});
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={3}
        >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className="investment-whole-div">
              <div className="profile-box2">
                <div className="invest-edit-abt">
                  <div>
                    <h5>About</h5>
                  </div>
                  <div>
                    {editKey === "about" ? (
                      <div>
                        {" "}
                        <Button type="submit">
                          <DoneOutlineIcon
                            sx={{ fontSize: "16px", cursor: "pointer" }}
                          />
                        </Button>
                      </div>
                    ) : (
                      <>
                       {editBtn === true && (
                      <EditIcon
                        sx={{
                          fontSize: "14px",
                          cursor: "pointer",
                          marginLeft: "5px",
                        }}
                        onClick={() => handleEditBtn("about")}
                      />
                    )}</>
                    )}
                  </div>
                </div>
                {/* <p className="para">{para}</p> */}
                {editKey === "about" ? (
                  <div className="d-inline-block">
                    <div className="d-flex align-items-start">
                      <div>
                        <Controller
                          name="about"
                          control={control}
                          defaultValue=""
                          rules={{ required: `Brief is required` }}
                          render={({ field }) => (
                            <textarea
                              type="text"
                              {...field}
                              className="invest-area"
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.about && errors.about.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span className="inv-prof-name">
                    <p className="invest-para">{investorpara}</p>
                  </span>
                )}
              </div>
              {/* <div className="profile-box2 mt-2">
              <h5 style={{ fontWeight: "600" }}>Founding Team</h5>

              <div style={{ width: "100%", overflowX: "auto" }}>
                <table
                  className="table"
                  style={{ width: "100%", borderCollapse: "collapse" }}
                >
                  <tbody>
                    {founder &&
                      founder.map((val, index) => (
                        <tr key={index}>
                          <td
                            style={{ padding: "0.75rem", verticalAlign: "top" }}
                          >
                            <div className="d-flex align-items-center founder-profile-img">
                              <img
                                src="/images/fouder1.png"
                                alt="FOUNDER"
                                className="img-fluid"
                              />
                              <div className="ml-3">
                                <p className="list-para mb-0">{val.name}</p>

                                <span
                                  style={{ fontSize: "10px", color: "#B3B3B3" }}
                                >
                                  {" "}
                                  Founder & Partner
                                </span>
                              </div>
                            </div>
                          </td>
                          <td
                            style={{ padding: "0.75rem", verticalAlign: "top" }}
                          >
                            <div className="soc-logos">
                              <div>
                                <a
                                  href={val.linkedin}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src="/images/X.png"
                                    alt="x-icon"
                                    className="linkedin-icn"
                                  />
                                </a>
                              </div>
                              <div>
                                <a
                                  href={val.linkedin}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src="/images/linkedin-logo.png"
                                    alt="linkedin-icon"
                                    className="linkedin-icn"
                                  />
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div> */}
              {/* <div className="Services-box">
                <span>Portfolio Startups</span>
                <div>
                  <Swiper
                    slidesPerView={screenSize}
                    spaceBetween={90}
                    autoplay={{
                      delay: 1000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="sup-sm-crds-inv">
                        <div className="service-card-back-layers">
                          <div className="layer-crd-back-img-inv">
                            <div className="service-card-back-profss">
                              <img
                                src="/images/GREENPOD_LOGO1 (1).png"
                                alt="logo"
                                className="smcrd-card-back-image"
                              />
                            </div>
                          </div>
                          <div className="card-content-offers">
                            <span>50 Fin</span>
                          </div>
                          <div className="card-content-offers1">
                            <img src={"/images/Page-1.png"} alt="globe" />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="sup-sm-crds-inv ml-3 mb-3">
                        <div className="service-card-back-layers">
                          <div className="layer-crd-back-img-inv">
                            <div className="service-card-back-profss">
                              <img
                                src="/images/GREENPOD_LOGO2.png"
                                alt="logo"
                                className="smcrd-card-back-image"
                              />
                            </div>
                          </div>
                          <div className="card-content-offers">
                            <span>ab Coffee</span>
                          </div>
                          <div className="card-content-offers1">
                            <img src={"/images/Page-1.png"} alt="globe" />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="sup-sm-crds-inv ml-3 mb-3">
                        <div className="service-card-back-layers">
                          <div className="layer-crd-back-img-inv">
                            <div className="service-card-back-profss">
                              <img
                                src="/images/GREENPOD_LOGO3.png"
                                alt="logo"
                                className="smcrd-card-back-image"
                              />
                            </div>
                          </div>
                          <div className="card-content-offers">
                            <span>accio Robotics</span>
                          </div>
                          <div className="card-content-offers1">
                            <img src={"/images/Page-1.png"} alt="globe" />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="sup-sm-crds-inv ml-3 mb-3">
                        <div className="service-card-back-layers">
                          <div className="layer-crd-back-img-inv">
                            <div className="service-card-back-profss">
                              <img
                                src="/images/GREENPOD_LOGO3.png"
                                alt="logo"
                                className="smcrd-card-back-image"
                              />
                            </div>
                          </div>
                          <div className="card-content-offers">
                            <span>accio Robotics</span>
                          </div>
                          <div className="card-content-offers1">
                            <img src={"/images/Page-1.png"} alt="globe" />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div> */}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="profile-box1">
              <div>
                <h6 style={{ fontWeight: "600" }}>Key Highlights</h6>
                <ul className="p-0">
                  <li className="d-flex align-items-start justify-content-start">
                    <div className="key-img mt-2">
                      <img src={"/images/pie-chart.png"} alt="pieChart" />
                    </div>
                    <div className="ml-3">
                      <>
                        <span className="list-span">Focused Sector</span>
                      </>
                      <>
                        <p className="list-para">
                          {formData.focusedSector ? (
                            <>
                              {formData.focusedSector.map((item, index) => (
                                <React.Fragment key={index}>
                                  {index > 0 && ", "}
                                  {item}
                                </React.Fragment>
                              ))}
                            </>
                          ) : (
                            "Focused sectors not available"
                          )}
                        </p>
                      </>
                    </div>
                  </li>
                  <li className="d-flex align-items-start justify-content-start">
                    <div className="key-img mt-2">
                      <img src={"/images/grow-up.png"} alt="pieChart" />
                    </div>
                    <div className="ml-3">
                      <>
                        <span className="list-span">
                        Preferred Stage of Startup
                        </span>
                      </>
                      <>
                      <p className="list-para">
                        
                      {formData.preferredStage ? (
                            <>
                              {formData.preferredStage.map((item, index) => (
                                <React.Fragment key={index}>
                                  {index > 0 && ", "}
                                  {item}
                                </React.Fragment>
                              ))}
                            </>
                          ) : (
                            <>
                            -
                            </>
                          )}
                          </p>
                      </>
                    </div>
                  </li>

                  <li className="d-flex align-items-start justify-content-start">
                    <div className="key-img mt-2">
                      <img src={"/images/money.png"} alt="pieChart" />
                    </div>
                    <div className="ml-3">
                      <>
                        <span className="list-span">Fund Ticket Size</span>
                      </>
                      <>
                      {formData.ticketSize ? (
                        <p className="list-para">{formData.ticketSize}</p>
                      ) : (
                        <p className="list-para">-</p>
                      )}
                      </>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default InvestorProfilePage;
