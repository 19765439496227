import { Autocomplete, Button, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import { FaArrowLeft } from "react-icons/fa6";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import notifyService from "../../../api/notifySerivce";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SearchableDropdown from "../event-details/SearchableDropdown";
import { RiCloseCircleFill } from "react-icons/ri";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { useParams } from "react-router-dom";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";
import cryptoJS from "../../../hooks/cryptoJS";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Viewimage from "../../../api/Viewimage";
const ProjectAdd = () => {
    const { id } = useParams()
    let projectId
    if (id != null) {
        projectId = atob(id)
    }
    const { setPageLoading, navigator, VisuallyHiddenInput, validateEmail, validateNumberonly, Accordion, AccordionSummary, AccordionDetails } = useContext(DataContext);
    const [formData, setFormData] = useState({})
    const { handleSubmit, control, setValue, reset, setError, clearErrors, getValues, watch, formState: { errors } } = useForm({ values: formData });


    useDidMountEffect(() => {
        sectorsdropdownApi()
        stagesdropdownApi()
        categoryApi()
        specialdropdownApi()
        servicedropdownApi()
        if (projectId != null) {
            departmentGetApi(projectId)
            setReadyOnlyValue(true)
        }
        funddropdownApi()
    }, []);
    const [readyOnlyValue, setReadyOnlyValue] = useState(false)
    const handleEditbtn = () => {
        setReadyOnlyValue(false)
    }
    const [inputValue, setInputValue] = useState("");
    // stage dropdown
    const [stages, setStages] = useState([]);
    const stagesdropdownApi = () => {
        apiService('stage/list', "", "unauthget")
            .then((result) => {
                if (result) {
                    if (result.data) {
                        if (result.data) {

                            setStages(result.data);
                        }
                    }
                }
            })
            .catch((err) => { });
    };
    // Funds dropdown Api
    const [funds, setFunds] = useState([]);
    const funddropdownApi = () => {
        apiService(`admin/dropdownvalues/get?key=${"fundtype"}`, "", "unauthget")
            .then((result) => {
                if (result) {
                    if (result.data) {
                        if (result.data.dropDownValues) {
                            var dropDown = result.data.dropDownValues;
                            setFunds(dropDown.values);
                        }
                    }
                }
            })
            .catch((err) => { });
    };
    // sector Dropdown
    const [sectors, setSectors] = useState([]);
    const sectorsdropdownApi = () => {
        apiService(`home/sector/list`, "", "get")
            .then((result) => {
                if (result) {
                    if (result.data) {
                        if (result.data) {
                            var values = result.data.sectors;
                            setSectors(values);
                        }
                    }
                }
            })
            .catch((err) => { });
    };
    ;
    // category Dropdown Api
    const [categoryList, setCategoryList] = useState([])
    const categoryApi = () => {
        apiService('home/category/list?type=project', "", "get").then((result) => {
            if (result && result.data && result.data.categories) {
                setCategoryList(result.data.categories)
            }
        }).catch((err) => {

        });
    }
    // special category Api
    const [specialCate, setSpecialCate] = useState([]);
    const specialdropdownApi = () => {
        apiService(`admin/dropdownvalues/get?key=${"specialcategories"}`, "", "unauthget")
            .then((result) => {
                if (result) {
                    if (result.data) {
                        if (result.data.dropDownValues) {
                            var dropDown = result.data.dropDownValues;
                            setSpecialCate(dropDown.values);
                        }
                    }
                }
            })
            .catch((err) => { });
    };
    // service DropdownApi 
    const [serviceLists, setServiceLists] = useState([])
    const servicedropdownApi = () => {
        apiService(`home/service/list`, "", "unauthget")
            .then((result) => {
                if (result) {
                    if (result.data && result.data.services) {

                        setServiceLists(result.data.services)

                    }
                }
            })
            .catch((err) => { });
    };
    const departmentGetApi = (id) => {
        setFormData({})
        setPageLoading(true)
        apiService(`project/get?id=${projectId}`, "", "get")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    var data = result.data

                    if (data.logoName) {
                        data.logoUrl = data.logoName
                    }

                    if (data.tags) {
                        setInputValue(data.tags)
                        setChips(data.tags)
                    }
                    if (data.imageName) {
                        setValue('image', data.imageName);
                        clearErrors('image'); // Clear errors after setting value
                    }
                    if (data.categoryName) {
                        if (data.categoryName === "Funding Program") {
                            setShowField(true)
                        }
                    }

                    setFormData(data)

                }
            })
            .catch((err) => { });
    }

    // const handleKeyDown = (e) => {
    //     if (e.key === "Enter") {
    //         e.preventDefault();
    //         addChip();
    //     }
    // };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim() && chips.length < 5) {
            e.preventDefault();
            setChips([...chips, inputValue.trim()]);
            setInputValue('');
        }
    };
    // const addChip = () => {
    //     if (inputValue.trim() !== "") {
    //         setChips([...chips, inputValue]);
    //         setInputValue("");
    //     }
    // };
    const [errorMessage, setErrorMessage] = useState('');
    const addChip = () => {
        if (inputValue.trim()) {
            if (chips.length < 5) {
                setChips([...chips, inputValue.trim()]);
                setInputValue('');
                setErrorMessage('');
            } else {
                setErrorMessage('You cannot add more than 5 tags.');
            }
        }
    };
    const handleDeleteChip = (index) => () => {
        setChips((chips) => chips.filter((_, i) => i !== index));
    };
    const [chips, setChips] = useState([]);


    const [authorityUserId, setauthorityUserId] = useState();


    // const ticketapplicables = watch("ticketapplicable", false);
    // const handleFilter2 = (event) => {
    //     const iseduIns = event.target.value === true;
    //     setValue("ticketapplicable", iseduIns);
    //     clearErrors("ticketapplicable");
    //     if (!iseduIns) {
    //         setValue("ticketapplicable", "");
    //         reset()
    //     }
    //     debugger
    // };
    const ticketapplicables = watch("ticketapplicable", false);
    const handleFilter2 = (event) => {
        const iseducationalInstitution = event.target.value === "true";
        setValue("ticketapplicable", iseducationalInstitution);
        if (!iseducationalInstitution) {
            setValue("ticketapplicable", false)
            setValue("ticketfrom", "");
            setValue("ticketto", "");
        }
    };

    const handleInputChange = (e) => {
        var val = e.target.value

        setInputValue(val);
        setValue('tags', val)
        clearErrors('tags')
        debugger
    };

    const [showField, setShowField] = useState(false)
    const handleCategories = (value) => {
        if (value === "Funding Program") {
            setShowField(true)
        } else {
            setShowField(false)
        }
        setValue('categoryName', value);
        clearErrors('categoryName');
        debugger
    }

    const handleSelectSector = (value) => {
        setValue('sector', value);
        clearErrors('sector');
    };
    const handleSelectService = (value) => {
        setValue('serviceName', value);
        clearErrors('serviceName');
        debugger
    }
    const handleSelectStage = (value) => {
        setValue('stages', value);
        clearErrors('stages');
    };
    const handleFundType = (value) => {
        setValue('fundType', value);
        clearErrors('fundType');
    };

    const handleSpecialCate = (value) => {
        setValue('specialCategory', value);
        clearErrors('specialCategory');
    }

    //   let logoselecetdFile = "";
    //   const handleFileUpload = (event, filekey, fileName, fileUrl) => {
    //     if (event !== null) {
    //       if (event.target === undefined) {
    //         logoselecetdFile = event;
    //       } else {
    //         logoselecetdFile = event.target.files[0];
    //       }
    //       const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
    //       if (logoselecetdFile) {
    //         if (logoselecetdFile.size < maxFileSize) {
    //           var reader = new FileReader();
    //           var imagetype = logoselecetdFile.type;
    //           var imagedatatype = imagetype.split("/");
    //           var img_crt_type = imagedatatype[1];
    //           if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png") {
    //             setValue(filekey, "");
    //             setValue(fileName, "");
    //             var fileValue = logoselecetdFile;
    //             reader.readAsDataURL(logoselecetdFile);
    //             reader.onload = () => {
    //               var logourl1 = reader.result;
    //               var spl = logourl1.split(",");
    //               var ImageValue = spl[1];
    //               var img_name = fileValue.name;
    //               setValue(filekey, ImageValue);
    //               setValue(fileName, img_name);
    //               setValue(fileUrl, logourl1)
    //               clearErrors(filekey);
    //               clearErrors(fileName);
    //               clearErrors(fileUrl);
    //             };
    //           } else {
    //             notifyService(
    //               "danger",
    //               "File Format Invalided",
    //               "Please check your file format"
    //             );
    //           }
    //         } else {
    //           notifyService(
    //             "danger",
    //             "File size exceeds 5MB",
    //             "Please check your file size"
    //           );
    //         }
    //       }
    //     }
    //   };
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const [viewImageName, setViewImageName] = useState("");
    const handleClickOpen = (value, name) => {
        setViewImage(value);
        setViewImageName(name);
        setOpen(true);
    };

    let logoselecetdFile = "";
    const handleFileUpload = (event, filekey, fileName, format) => {
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type;
                    var imagedatatype = imagetype.split("/");
                    var img_crt_type = imagedatatype[1];
                    if (
                        ((format === 'file') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf")) ||
                        ((format === 'image') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png"))
                    ) {
                        setValue(filekey, "");
                        setValue(fileName, "");
                        // setValue(fileUrl, '')
                        var fileValue = logoselecetdFile;
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(",");
                            var ImageValue = spl[1];
                            var img_name = fileValue.name;
                            // setUrl(logourl1)
                            setValue(filekey, ImageValue);
                            setValue(fileName, img_name);
                            // setValue(fileUrl, logourl1)
                            clearErrors(filekey);
                            clearErrors(fileName);
                            // clearErrors(fileUrl);
                            // 
                            // clearErrors('dpiitCertificateName')
                        };
                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalided",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        `Maximum file size allowed is 5 MB`
                    );
                }
            }
        }
    };



    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = (data) => {
        // setPageLoading(true)
        debugger
        // if (!chips.length && inputValue) {
        //     setError('tags', {
        //         type: 'manual',
        //         message: 'Press Enter to add the tag before submitting.',
        //     });
        //     return;
        // }
        if (chips.length === 0) {
            setError('tags', { type: 'manual', message: 'Tags Required' });
            return;
        }

        debugger
        var categoryId = categoryList.filter((list) => list.category === data.categoryName)
        data.categoryId = categoryId[0].id

        var serviceId = serviceLists.filter((list) => list.service === data.serviceName)
        data.serviceId = serviceId[0].id
        debugger
        // let catePush = []
        // element.categories.filter((category) => {

        // })
        let secpush = []
        data.sector.filter((sector) => {
            var sectorId = sectors.filter((list) => list.name === sector)
            debugger
            return secpush.push(sectorId[0].id)
        })
        // element.categoryIds = catePush
        data.sectorIds = secpush

        let stagepush = []
        data.stages.filter((stage) => {
            var stageId = stages.filter((list) => list.name === stage)
            debugger
            return stagepush.push(stageId[0].id)
        })
        data.stageIds = stagepush
        data.tags = chips;
        debugger

        console.log(data)

        apiService("project/save", data, "post")
            .then((result) => {
                setPageLoading(false)
                if (result.data.responseStatus === "Success") {
                    notifyService("success", "Success", "");
                    navigator("/admin/project/list");
                }
            })
            .catch((err) => { });
    };
    const onError = (event) => {
        console.log("error Data:", event);
        debugger
        notifyService(
            "danger",
            "Submit Failed",
            "Please check the mandatory fields"
        );
    };
    const handleReset = () => {
        reset();
        navigator("/admin/project/list");
    };
    const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
    return (
        <div className="deap-Add-form">
            <div className='event_add_header my-4'>
                <div className='d-flex w-100 align-items-center'>
                    <div>
                        <p className="back-btns mb-0" onClick={handleReset}>
                            <FaArrowLeft />
                            <span style={{ marginLeft: "5px" }}>Back</span>
                        </p>
                    </div>
                    <div className='m-auto'>
                        <h4 className='font-color-black'>{projectId ? 'Update' : 'Add'} Project</h4>
                        <p>See and add your Project information</p>
                    </div>
                    <div>
                        {(projectId && readyOnlyValue === true) && (
                            <Button onClick={() => handleEditbtn()} className="publish-button">
                                Edit
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className="dep-add-form">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="mb-4">


                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={1}>
                                    <Grid item lg={6} md={6} sm={12} >
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <div className="input_background">
                                                    <Controller
                                                        name="name"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: "Project Name is required",
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                variant="outlined"
                                                                label="Project Name *"
                                                                placeholder="Enter Project title"
                                                                fullWidth
                                                                type="text"
                                                                inputProps={{ readOnly: readyOnlyValue }}
                                                                {...field}

                                                                error={Boolean(errors.name && errors.name)}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.name && errors.name.message}
                                                    </FormHelperText>
                                                </div>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <div className="input_background">
                                                    <Controller
                                                        name="categoryName"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: "Categories is required",
                                                        }}
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                disableClearable
                                                                disablePortal
                                                                {...field}
                                                                // multiple={true}
                                                                // limitTags={1}
                                                                // value={Array.isArray(field.value) ? field.value : ''}
                                                                options={Array.isArray(categoryList) ? categoryList.map((option) => option.category) : []}
                                                                onChange={(e, selectedOptions) =>
                                                                    handleCategories(selectedOptions)
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="Categories *" placeholder="Select Categories" error={Boolean(
                                                                        errors.categoryName &&
                                                                        errors.categoryName
                                                                    )} />
                                                                )}
                                                                disabled={readyOnlyValue}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.categoryName &&
                                                            errors.categoryName.message}
                                                    </FormHelperText>
                                                </div>
                                            </Grid>
                                            {showField && (
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <div className="input_background">
                                                        <Controller
                                                            name="fundType"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: true }}
                                                            render={({
                                                                field: { onChange, value },
                                                                fieldState: { error },
                                                            }) => (
                                                                <Autocomplete
                                                                    disableClearable

                                                                    options={funds}
                                                                    onChange={(e, selectedOptions) =>
                                                                        handleFundType(selectedOptions)
                                                                    }
                                                                    defaultValue={[]}
                                                                    value={value || []}
                                                                    on
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Fund Type *"
                                                                            placeholder="Select Fund Type "
                                                                            error={!!error}
                                                                            helperText={error ? "Fund Type is required" : ""}
                                                                        />

                                                                    )}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    disabled={readyOnlyValue}
                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText className="text-danger">
                                                            {errors.fundType &&
                                                                errors.fundType.message}
                                                        </FormHelperText>
                                                    </div>
                                                </Grid>
                                            )}
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <div className="input_background">
                                                    <Controller
                                                        name="specialCategory"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: true }}
                                                        render={({
                                                            field: { onChange, value },
                                                            fieldState: { error },
                                                        }) => (
                                                            <Autocomplete
                                                                disableClearable

                                                                options={specialCate}
                                                                onChange={(e, selectedOptions) =>
                                                                    handleSpecialCate(selectedOptions)
                                                                }
                                                                defaultValue={[]}
                                                                value={value || []}
                                                                on
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Special Categories *"
                                                                        placeholder="Select Special Categories"
                                                                        error={!!error}
                                                                        helperText={error ? "Special Categories is required" : ""}
                                                                    />

                                                                )}
                                                                fullWidth
                                                                margin="normal"
                                                                disabled={readyOnlyValue}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.specialcategory &&
                                                            errors.specialcategory.message}
                                                    </FormHelperText>
                                                </div>
                                            </Grid>

                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <div className="input_background">
                                                    <Controller
                                                        name="sector"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: "Sector is required",
                                                        }}
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                disableClearable
                                                                disablePortal
                                                                {...field}
                                                                multiple={true}
                                                                limitTags={1}
                                                                value={Array.isArray(field.value) ? field.value : []}
                                                                options={Array.isArray(sectors) ? sectors.map((option) => option.name) : []}
                                                                onChange={(e, selectedOptions) =>
                                                                    handleSelectSector(selectedOptions,)
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="Sectors *" placeholder="Select Sectors" error={Boolean(
                                                                        errors.sector &&
                                                                        errors.sector
                                                                    )} />
                                                                )}
                                                                disabled={readyOnlyValue}
                                                            />

                                                        )}

                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.sector &&
                                                            errors.sector.message}
                                                    </FormHelperText>
                                                </div>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <div className="input_background">
                                                    <Controller
                                                        name="stages"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: "Stages is Required" }}
                                                        render={({
                                                            field
                                                        }) => (
                                                            <Autocomplete
                                                                {...field}
                                                                disableClearable

                                                                multiple={true}

                                                                defaultValue={[]}
                                                                value={Array.isArray(field.value) ? field.value : []}
                                                                options={Array.isArray(stages) ? stages.map((option) => option.name) : []}

                                                                onChange={(e, selectedOptions) =>
                                                                    handleSelectStage(selectedOptions)
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Stages *"
                                                                        placeholder="Select Stage "
                                                                        error={errors.stages &&
                                                                            errors.stages}

                                                                    />
                                                                )}
                                                                fullWidth
                                                                margin="normal"
                                                                disabled={readyOnlyValue}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.stages &&
                                                            errors.stages.message}
                                                    </FormHelperText>
                                                </div>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <div className="input_background">
                                                    <Controller
                                                        name="redirecturl"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: "Redirect URL is required",
                                                            pattern: {
                                                                value: urlPattern,
                                                                message: "Invalid URL format"
                                                            }
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                variant="outlined"
                                                                label="Redirect URL *"
                                                                placeholder="Enter Redirect URL"
                                                                fullWidth
                                                                type="text"
                                                                {...field}
                                                                inputProps={{ readOnly: readyOnlyValue }}
                                                                error={Boolean(errors.redirecturl && errors.redirecturl)}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.redirecturl && errors.redirecturl.message}
                                                    </FormHelperText>
                                                </div>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <div className="input_background">
                                                    <Controller
                                                        name="serviceName"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: "Service is required",
                                                        }}
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                disableClearable
                                                                disablePortal
                                                                {...field}
                                                                // multiple={true}
                                                                // limitTags={1}
                                                                // value={Array.isArray(field.value) ? field.value : []}
                                                                options={Array.isArray(serviceLists) ? serviceLists.map((option) => option.service) : []}
                                                                onChange={(e, selectedOptions) =>
                                                                    handleSelectService(selectedOptions,)
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="Service *" placeholder="Select Service" error={Boolean(
                                                                        errors.serviceName &&
                                                                        errors.serviceName
                                                                    )} />
                                                                )}
                                                                disabled={readyOnlyValue}
                                                            />

                                                        )}

                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.serviceName &&
                                                            errors.serviceName.message}
                                                    </FormHelperText>
                                                </div>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                {/* <div className="input_background">
                                                    <div className="mb-3">
                                                        <Controller
                                                            name="tags"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{
                                                                required:"Tags Required",
                                                                validate: value => chips.length > 0 || "",
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    variant="outlined"
                                                                    label="Tags *"
                                                                    placeholder="Enter Tags"
                                                                    fullWidth
                                                                    type="text"
                                                                    {...field}
                                                                    value={inputValue}
                                                                    inputProps={{ readOnly: readyOnlyValue }}

                                                                    onChange={handleInputChange}
                                                                    onKeyDown={handleKeyDown}
                                                                    error={Boolean(
                                                                        errors.tags &&
                                                                        !chips.length &&
                                                                        !inputValue &&
                                                                        errors?.tags?.message
                                                                    )}
                                                                    helperText={errors.tags &&
                                                                        !chips.length &&
                                                                        !inputValue &&
                                                                        errors?.tags?.message}
                                                                />
                                                            )}
                                                        />
                                                         <FormHelperText >
                                                                Maximum 5 Tags Only Allowed.
                                                            </FormHelperText>
                                                        {errors.tags && !chips.length && !inputValue && !chips.length > 1 && (
                                                            <FormHelperText className="text-danger">
                                                                {errors.tags.message}
                                                            </FormHelperText>
                                                        )}
                                                    </div>
                                                    <Stack direction="row" spacing={1}>
                                                        {chips.map((chip, index) => (
                                                            <Chip
                                                                disabled={readyOnlyValue}
                                                                key={index}
                                                                label={chip}
                                                                onDelete={handleDeleteChip(index)}
                                                                style={{ marginRight: "5px" }}
                                                            />
                                                        ))}
                                                    </Stack>
                                                </div> */}
                                                <div className="input_background">
                                                    {/* <div className="mb-3">
                                                        <Controller
                                                            name="tags"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{
                                                                required: "Tags Required",
                                                                validate: value => chips.length > 0 || "Press Enter to add the tag before submitting.",
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    variant="outlined"
                                                                    label="Tags *"
                                                                    placeholder="Enter Tags"
                                                                    fullWidth
                                                                    type="text"
                                                                    {...field}
                                                                    value={inputValue}
                                                                    inputProps={{ readOnly: readyOnlyValue }}
                                                                    onChange={handleInputChange}
                                                                    onKeyDown={handleKeyDown}
                                                                    error={Boolean(errors.tags)}
                                                                    helperText={
                                                                        errors.tags
                                                                            ? errors.tags.message
                                                                            : "Maximum 5 Tags Only Allowed."
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                       
                                                    </div> */}
                                                    <div className="mb-3">
                                                        <Controller
                                                            name="tags"
                                                            control={control}
                                                            defaultValue=""
                                                            // rules={{
                                                            //     required: chips.length === 0 ? "Tags Required" : false,
                                                            //     validate: value => chips.length <= 5 || "Maximum 5 Tags Only Allowed.",
                                                            // }}
                                                            rules={{
                                                                validate: value => {
                                                                    if (chips.length > 0) return true;
                                                                    return inputValue ? "Press Enter to add the tag before submitting." : "Tags Required";
                                                                },
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    variant="outlined"
                                                                    label="Tags *"
                                                                    placeholder="Enter Tags"
                                                                    fullWidth
                                                                    type="text"
                                                                    {...field}
                                                                    value={inputValue}
                                                                    inputProps={{ readOnly: readyOnlyValue }}
                                                                    // inputProps={{ readOnly: chips.length >= 5 }}
                                                                    onChange={handleInputChange}
                                                                    onKeyDown={handleKeyDown}
                                                                    error={Boolean(errors.tags)}
                                                                    helperText={
                                                                        errors.tags ? errors.tags.message : "Maximum 5 Tags Only Allowed."
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    {/* Displaying the added chips */}
                                                    <Stack direction="row" spacing={1}>
                                                        {chips.map((chip, index) => (
                                                            <Chip
                                                                disabled={readyOnlyValue}
                                                                key={index}
                                                                label={chip}
                                                                onDelete={handleDeleteChip(index)}
                                                                style={{ marginRight: "5px" }}
                                                            />
                                                        ))}
                                                    </Stack>
                                                </div>

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={12} >
                                        {showField && (

                                            <div className="mb-3">
                                                <div className="input_background3">
                                                    <p>
                                                        Is Ticket size applicable ?
                                                    </p>
                                                    {/* <Controller
                                                    name="ticketapplicable"
                                                    control={control}
                                                    rules={{ required: false }}
                                                    render={({ field }) => (
                                                        <FormControl component="fieldset" className="d-flex" >
                                                            <RadioGroup {...field} row value={ticketapplicables ? true : false} onChange={handleFilter2} >
                                                                <FormControlLabel disabled={readyOnlyValue}  value={true}control={<Radio />} label="Yes" />
                                                                <FormControlLabel disabled={readyOnlyValue} value={false} control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.ticketapplicable && errors.ticketapplicable.message}
                                                </FormHelperText> */}
                                                    <Controller
                                                        name="ticketapplicable"
                                                        control={control}
                                                        rules={{ required: false }}
                                                        render={({ field }) => (
                                                            <FormControl component="fieldset" className="d-flex">
                                                                <RadioGroup
                                                                    {...field}
                                                                    row
                                                                    value={ticketapplicables ? "true" : "false"}
                                                                    onChange={handleFilter2}
                                                                >
                                                                    <FormControlLabel
                                                                        value={true}
                                                                        control={<Radio />}
                                                                        disabled={readyOnlyValue}
                                                                        label="Yes"
                                                                    />
                                                                    <FormControlLabel
                                                                        value={false}
                                                                        control={<Radio />}
                                                                        disabled={readyOnlyValue}
                                                                        label="No"
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.ticketapplicable &&
                                                            errors.ticketapplicable.message}
                                                    </FormHelperText>

                                                    <Grid container spacing={2}>
                                                        <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3" >
                                                            {ticketapplicables && (
                                                                <div>
                                                                    <Controller
                                                                        name="ticketfrom"
                                                                        control={control}
                                                                        defaultValue=""

                                                                        rules={{
                                                                            required: 'From Ticket size is required',
                                                                        }}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                id="outlined-multiline-static"
                                                                                label="From Ticket size *"
                                                                                fullWidth
                                                                                {...field}
                                                                                inputProps={{ readOnly: readyOnlyValue }}
                                                                                defaultValue=""
                                                                                onKeyDown={(e) => validateNumberonly(e)}
                                                                                error={Boolean(
                                                                                    errors.ticketfrom && errors.ticketfrom
                                                                                )}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <FormHelperText className="text-danger">
                                                                        {errors.ticketfrom && errors.ticketfrom.message}
                                                                    </FormHelperText>
                                                                </div>
                                                            )}
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3" >
                                                            {ticketapplicables && (
                                                                <div>
                                                                    <Controller
                                                                        name="ticketto"
                                                                        control={control}
                                                                        defaultValue=""

                                                                        rules={{
                                                                            required: 'To Ticket size is required',
                                                                        }}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                id="outlined-multiline-static"
                                                                                label="To Ticket size *"
                                                                                fullWidth
                                                                                inputProps={{ readOnly: readyOnlyValue }}
                                                                                {...field}
                                                                                onKeyDown={(e) => validateNumberonly(e)}
                                                                                defaultValue=""
                                                                                error={Boolean(
                                                                                    errors.ticketto && errors.ticketto
                                                                                )}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <FormHelperText className="text-danger">
                                                                        {errors.ticketto && errors.ticketto.message}
                                                                    </FormHelperText>
                                                                </div>
                                                            )}
                                                        </Grid>
                                                    </Grid>

                                                </div>
                                            </div>
                                        )}
                                        <div className="mb-3">
                                            {/* <div className="input_background5">
                                                {
                                                    getValues('imageName') ? (
                                                        <>
                                                            <label >Upload Project Logo<span className="text-danger ml-1">*</span></label>
                                                            <div className="row">
                                                                {readyOnlyValue === false && (
                                                                    <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">

                                                                        <Controller
                                                                            name="image"
                                                                            control={control}
                                                                            defaultValue=""
                                                                            rules={{ required: "Project Logo is required" }}
                                                                            render={({ field }) => (
                                                                                <Button
                                                                                    component="label"
                                                                                    variant="contained"
                                                                                    {...field}
                                                                                    fullWidth
                                                                                    onChange={(e) =>
                                                                                        handleFileUpload(e, "image", "imageName", 'image')
                                                                                    }
                                                                                    startIcon={<CloudUploadIcon />}
                                                                                    href="#file-upload"
                                                                                >
                                                                                    Change
                                                                                    <VisuallyHiddenInput type="file" />
                                                                                </Button>
                                                                            )}
                                                                        />

                                                                    </Grid>
                                                                )}
                                                                <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3 logo-btn-margin">
                                                                    <Button
                                                                        className="view_doc_btn_document c-pointer"

                                                                        size="medium"
                                                                        variant="outlined"
                                                                        onClick={() =>
                                                                            handleClickOpen(
                                                                                getValues("image"),
                                                                                getValues("imageName")
                                                                            )
                                                                        }
                                                                    >
                                                                        Preview
                                                                    </Button>
                                                                </Grid>
                                                                <div>
                                                                    <span className="fn-12   mt-1 ">
                                                                        Maximum 5 mb allowed doc (Accepted Format: jpeg, jpg, png)

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>

                                                            <div className="form-group mt-2">
                                                                <Controller
                                                                    name="imageName"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            variant="outlined"
                                                                            label="Upload Project Logo *"
                                                                            placeholder="Upload Image "
                                                                            fullWidth
                                                                            type="text"
                                                                            {...field}
                                                                            disabled

                                                                            error={(Boolean(errors.imageName && errors.imageName))}
                                                                        />
                                                                    )}
                                                                />
                                                                <FormHelperText className="text-danger">
                                                                    {errors.imageName && errors.imageName.message}
                                                                </FormHelperText>
                                                                <div className='d-flex align-items-center w-100 my-3'>
                                                                    <p className='font-sm'>
                                                                        Recommended aspect ratio 4:3 <br />
                                                                        Max size upto 5mb <br />
                                                                        Accepted Format: jpeg, jpg, png</p>
                                                                    <label className='ml-auto lbl_btn1'
                                                                        onChange={(e) => { readyOnlyValue === false && handleFileUpload(e, "image", "imageName", 'image') }}
                                                                        href="#file-upload">
                                                                        <img src='/images/admin/upload.png' className='mr-3' alt='' />
                                                                        Upload
                                                                        <VisuallyHiddenInput type="file" disabled={readyOnlyValue} />
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </>
                                                    )
                                                }

                                            </div> */}
                                            <div className="input_background5">
                                                {
                                                    getValues('imageName') ? (
                                                        <>
                                                            <label >Upload Project Logo<span className="text-danger ml-1">*</span></label>
                                                            <div className="row">
                                                                {
                                                                    (projectId && readyOnlyValue === true) ? (
                                                                        <></>
                                                                    ) : (
                                                                        <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">
                                                                            <Controller
                                                                                name="imageName"
                                                                                control={control}
                                                                                defaultValue=""
                                                                                rules={{ required: "Project Logo is required" }}
                                                                                render={({ field }) => (

                                                                                    <Button
                                                                                        component="label"
                                                                                        variant="contained"
                                                                                        {...field}
                                                                                        fullWidth
                                                                                        // onChange={(e) =>
                                                                                        //     handleFileUpload(e, "image", "imageName", 'image')
                                                                                        // }
                                                                                        onChange={(e) =>
                                                                                            handleFileUpload(e, "image", "imageName", 'image')
                                                                                        }
                                                                                        startIcon={<CloudUploadIcon />}
                                                                                        href="#file-upload"
                                                                                    >
                                                                                        Change
                                                                                        <VisuallyHiddenInput type="file" />
                                                                                    </Button>
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                    )
                                                                }
                                                                <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3 logo-btn-margin">
                                                                    <Button
                                                                        className="view_doc_btn_document c-pointer"

                                                                        size="medium"
                                                                        variant="outlined"
                                                                        onClick={() =>
                                                                            handleClickOpen(
                                                                                getValues("image"),
                                                                                getValues("imageName")
                                                                            )
                                                                        }
                                                                    >
                                                                        Preview
                                                                    </Button>
                                                                </Grid>
                                                                <div>
                                                                    <span className="fn-12   mt-1 ">
                                                                        Maximum 5 mb allowed doc (Accepted Format: jpeg, jpg, png)

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="form-group mt-2">
                                                            <Controller
                                                                name="imageName"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{ required: "Project Logo is required" }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label="Upload Project Logo *"
                                                                        placeholder="Upload Image "
                                                                        fullWidth
                                                                        type="text"
                                                                        {...field}
                                                                        disabled
                                                                    />
                                                                )}
                                                            />
                                                            <FormHelperText className="text-danger">
                                                                {errors.imageName && errors.imageName.message}
                                                            </FormHelperText>
                                                            <div className='d-flex align-items-center w-100 my-3'>
                                                                <p className='font-sm'>
                                                                    Recommended aspect ratio 4:3 <br />
                                                                    Max size upto 5mb <br />
                                                                    Accepted Format: jpeg, jpg, png</p>
                                                                <label className='ml-auto lbl_btn1'
                                                                    onChange={(e) => { readyOnlyValue === false && handleFileUpload(e, "image", "imageName", 'image') }}
                                                                    href="#file-upload">
                                                                    <img src='/images/admin/upload.png' className='mr-3' alt='' />
                                                                    Upload
                                                                    <VisuallyHiddenInput type="file" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>


                                        <div className="mb-3">
                                            <div className="input_background1">
                                                <Controller
                                                    name="brief"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Description is required" }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            id="outlined-multiline-static"
                                                            label="Description *"
                                                            placeholder="Enter your Description"
                                                            fullWidth
                                                            multiline
                                                            rows={5}
                                                            inputProps={{ readOnly: readyOnlyValue }}
                                                            defaultValue=""
                                                            error={Boolean(
                                                                errors.brief &&
                                                                errors.brief
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.brief &&
                                                        errors.brief.message}
                                                </FormHelperText>
                                            </div>

                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <div className="text-center mb-3 mt-4 w-100">
                            <Button onClick={handleReset} className="discard-btn">
                                Close
                            </Button>
                            {readyOnlyValue === false ? (
                                <Button type="submit" className="publish-button">
                                    {projectId ? 'Update' : 'Save'}
                                </Button>
                            ) : (<></>)}

                        </div>
                    </div>
                </form>
            </div>
            <Viewimage
                viewImage={viewImage}
                viewImageName={viewImageName}
                open={open}
                handleClose={handleClose}
            />
        </div>
    );
};

export default ProjectAdd;
