import React, { useContext, useState } from 'react'
import { FormHelperText, Grid, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import DataContext from '../../../../context/DataContext'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import apiService from '../../../../api/apiService'
import notifyService from '../../../../api/notifySerivce'
import cryptoJS from '../../../../hooks/cryptoJS'
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS'

const BasicinfoAspirants = () => {
  const { validatePhoneNumber, isWhitespace, navigator, validateEmail, validateNumberonly, setPageLoading, handleNextClick, validatePANNumber,checkUppernumeric } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, formState: { errors }, } = useForm({ values: formData });


  var id = localStorage.getItem('id')
  const roleval = localStorage.getItem('role')
  var type = localStorage.getItem('type')

  useDidMountEffect(() => {
    handleNextClick(60)
    if (id !== null) {
      getApi()
    }
  }, [])

  const getApi = () => {
    setPageLoading(true)
    apiService(`userprofile/get?persona=${type}&userid=${id}`, '', 'get').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        var data = result.data
        if (data.phone) {
          data.phone = cryptodecryptJS(data.phone);
        }
        if (data.email) {
          data.email = cryptodecryptJS(data.email);
        }
        if (data.pan) {
          data.pan = cryptodecryptJS(data.pan)
          // setPanTickIcon(1)
        }
        setFormData(data)
      }
    }).catch((err) => {
    });
  }
  const onSubmit = (data) => {
    console.log('Form Data:', data);
    setPageLoading(true)
    data.persona = type
    data.userId = id
    data.phone = cryptoJS(data.phone)
    data.email = cryptoJS(data.email.toLowerCase())
    data.pan = cryptoJS(data.pan.toUpperCase());
    apiService('userprofile/save', data, 'post').then((result) => {
      setPageLoading(false)
      if (result && result.data && result.data.responseStatus === 'Success') {
        navigator('/aspirants/address')
      }
    }).catch((err) => {

    });
  };
  const onError = () => {
    notifyService("danger", "Submit Failed", "Please check the mandatory fields");
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className='signup-data_div'>
            <div className='forms-title d-flex'>
              <p>Organization Info {`>`} <span className='path_sapn'>Basic Info</span></p>
              <p className='ml-auto'><span className='count_num'>1</span>/5</p>
            </div>
            <div className='signup_box_div mt-4'>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'Aspirant Name is required', validate: {
                          noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Aspirant Name*"
                          placeholder="Enter Aspirant Name"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.name && errors.name)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.name && errors.name.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Aspirant Mail ID is required", validate: validateEmail }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Aspirant Mail ID*"
                          placeholder="Enter Aspirant Mail ID"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.email && errors.email.message)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.email && errors.email.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Phone Number is required",
                        validate: validatePhoneNumber,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Phone Number*"
                          placeholder="Enter Phone Number"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.phone && errors.phone)}
                          onKeyDown={(e) => validateNumberonly(e)}
                          inputProps={{ maxLength: 10 }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.phone && errors.phone.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>

                    <div className='mb-3'>

                      {
                        roleval === 'STUDENT' ? (
                          <>
                            <div className='mb-3'>
                              <Controller
                                name="orgOrInstitution"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: 'Education Institution Name is required', validate: {
                                    noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Education Institution Name *"
                                    placeholder='Enter Education Institution Name'
                                    fullWidth
                                    {...field}
                                    defaultValue=""
                                    error={Boolean(errors.orgOrInstitution && errors.orgOrInstitution)}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.orgOrInstitution && errors.orgOrInstitution.message}
                              </FormHelperText>
                            </div>
                          </>
                        ) : (<></>)
                      }
                      {
                        roleval === 'EMPLOYEE' ? (
                          <>
                            <div className='mb-3'>
                              <Controller
                                name="orgOrInstitution"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: 'Your Company Name is required', validate: {
                                    noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Company Name *"
                                    placeholder='Enter Company Name'
                                    fullWidth
                                    {...field}
                                    defaultValue=""
                                    error={Boolean(errors.orgOrInstitution && errors.orgOrInstitution)}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.orgOrInstitution && errors.orgOrInstitution.message}
                              </FormHelperText>
                            </div>
                          </>
                        ) : (<></>)
                      }
                      {
                        roleval === 'TRADITIONAL-BUSINESS-OWNER' ? (
                          <>
                            <div className='mb-3'>
                              <Controller
                                name="orgOrInstitution"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: 'Organization Name is required', validate: {
                                    noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Organization Name *"
                                    placeholder='Enter Organization Name'
                                    fullWidth
                                    {...field}
                                    defaultValue=""
                                    error={Boolean(errors.orgOrInstitution && errors.orgOrInstitution)}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.orgOrInstitution && errors.orgOrInstitution.message}
                              </FormHelperText>
                            </div>
                          </>
                        ) : (<></>)
                      }
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="pan"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "PAN No. is required",
                        // validate: validatePANNumber
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="PAN No. *"
                          placeholder="Enter PAN No."
                          fullWidth
                          type="text"
                          // inputProps={{ maxLength: 10 }}
                          // onKeyDown={(e) => checkUppernumeric(e)}
                          // onInput={(e) => {
                          //   handlePanChange(e);
                          // }}
                          {...field}
                          error={Boolean(errors.pan && errors.pan)}
                        // InputProps={{
                        //   endAdornment: panTickIcon === 1 ? (
                        //     <InputAdornment position="end">
                        //       <CheckCircleIcon color="success" />
                        //     </InputAdornment>
                        //   ) : panTickIcon === 2 ? (
                        //     <InputAdornment position="end">
                        //       <CloseIcon color="error" />
                        //     </InputAdornment>
                        //   ) : null,
                        // }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.pan && errors.pan.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='text-center'>
                    <button className='next-btn' type="submit">Next</button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default BasicinfoAspirants