import { Button, Grid, Box, Divider, FormHelperText, Autocomplete, FormControl, TextField, DialogTitle } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import { Autoplay } from "swiper/modules";
import DialogContent from "@mui/material/DialogContent";
import { Swiper, SwiperSlide } from "swiper/react";
import DialogContentText from "@mui/material/DialogContentText";
// import UserProfileReport from "./UserProfileReport";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { CiGlobe } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import EditIcon from "@mui/icons-material/Edit";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import notifyService from "../../../../api/notifySerivce";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import downloadPDF from "../../../../hooks/downloadPDF";
import Navbar from "../../../home-module/navbar-page/Navbar";
import cryptoJS from "../../../../hooks/cryptoJS";
import MaskDialog from "../../Mask-Dialogbox/MaskDialog";

const EnablersProfilePage = () => {
    const { navigator, validateNumberonly, setPageLoading, VisuallyHiddenInput, validatePhoneNumber, fullScreen, modalstyle,
        linkedInPattern, validateEmail,
        getApi, para, formData, handleOpenEdit, editBtn, setEditBtn, handleEditBtn, editKey, handlemaskClickOpen, setEditKey, userProfileId, showViewbtn } = useContext(DataContext);

    const roleval = localStorage.getItem('role')
    const { handleSubmit, control, setValue, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });

    useDidMountEffect(() => {
        getApi();
    }, [userProfileId, showViewbtn]);
    const [showFull, setShowFull] = useState(false);
    const toggleReadMore = () => {
        setShowFull(!showFull);
    };
    // const fullText = "Apple Inc. is a global Apple Inc. is a globalApple Inc. is a globalApple Inc. is a global";
    const truncateText = (text, wordLimit) => {
        const words = text.split(" ");
        return words.length > wordLimit
            ? words.slice(0, wordLimit).join(" ") + "..."
            : text;
    };
    const wordLimit = 30;
    const displayText = showFull ? para : truncateText(para, wordLimit);
    const isTruncated = para.split(" ").length > wordLimit;

    const onSubmit = (data) => {
        data.phone = cryptoJS(data.phone)
        data.email = cryptoJS(data.email)
        apiService("userprofile/save", data, "post")
            .then((result) => {
                if (result.data.responseStatus === "Success") {
                    if (editKey === "") {
                        setEditBtn(false);
                    } else {
                        setEditKey("");
                    }
                    getApi();
                }
            })
            .catch((err) => { });
    };
    const onError = (e) => {
        console.log(e)
    };
    let logoselecetdFile = "";
    const handleFileUpload = (event, filekey, fileName, fileUrl) => {
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type;
                    var imagedatatype = imagetype.split("/");
                    var img_crt_type = imagedatatype[1];
                    if (
                        img_crt_type === "jpg" ||
                        img_crt_type === "png" ||
                        img_crt_type === "jpeg"
                    ) {
                        setValue(filekey, "");
                        setValue(fileName, "");
                        setValue(fileUrl, '')
                        var fileValue = logoselecetdFile;
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(",");
                            var ImageValue = spl[1];
                            var img_name = fileValue.name;
                            // setUrl(logourl1)
                            setValue(filekey, ImageValue);
                            setValue(fileName, img_name);
                            setValue(fileUrl, logourl1)
                            clearErrors(filekey);
                            clearErrors(fileName);
                        };
                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalided",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        `Maximum file size allowed is 5 MB`
                    );
                }
            }
        }
    };
    const [qrModalopen, setQrModalOpen] = React.useState(false);
    const handleClickOpen = () => {
        setQrModalOpen(true);
    };

    const handleModalClose = () => {
        setQrModalOpen(false);
    };
    const downloadBarcode = () => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', formData.barcode);
        link.setAttribute('download', formData.barcode); // Set desired file name here
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    useEffect(() => {
        size();
    }, []);
    const [screenSize, setScreenSize] = useState(3);
    const size = () => {
        if (window.innerWidth > 1440) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
            setScreenSize(2);
        } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
            setScreenSize(2);
        } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
            setScreenSize(2);
        } else {
            setScreenSize(1);
        }
    };
    // const founder = [
    //     {
    //         name: 'John Doe',
    //         linkedin: 'https://www.linkedin.com/in/johndoe',
    //     },
    //     {
    //         name: 'Jane Smith',
    //         linkedin: 'https://www.linkedin.com/in/janesmith',
    //     },

    // ];
    return (
        <>
            <div className="bg-color">
                <div>
                    <Navbar />

                    <div className="container-fluid flu-class">
                        <div className="ecosystem-prof-img common-bg2"></div>
                        {/* {(roleval === 'BANK' || roleval === 'MEDIA-AGENCY' || roleval === 'BLOGGER-INFLUENCER' || roleval === 'NGO') && (
                            <div className="ecosystem-prof-img common-bg"></div>
                        )}
                        <div className="ecosystem-prof-img common-bg"></div>
                        {(roleval === 'INCUBATOR' && (<div className="ecosystem-prof-img investor-bg"></div>))}
                        {(roleval === 'ACCELARATOR' && (<div className="ecosystem-prof-img accelartor-bg"></div>))}
                        {(roleval === 'EDU-INST' && (<div className="ecosystem-prof-img EDU-INST-bg"></div>))}
                        {(roleval === 'BUSINESS-SOCIAL-FORM' && (<div className="ecosystem-prof-img Business-bg"></div>))}
                        {(roleval === 'GOVT-AGENCY' && (<div className="ecosystem-prof-img govt-bg"></div>))}
                        {(roleval === 'CORPORATE' && (<div className="ecosystem-prof-img CORPORATE-bg"></div>))} */}

                        <div className="user-profile">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="user-boxs">
                                    <div className="first-box">
                                        <div className="all-userdetails1">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    {editKey === "imageName" ? (
                                                        <div className="form-group mt-2">
                                                            <div className="logo_div">
                                                                <img
                                                                    src={getValues('imageUrl')}
                                                                    className="user_logo"
                                                                    alt={getValues('imageName')}
                                                                />
                                                                <div className="camera_icon">
                                                                    <Controller
                                                                        name="imageName"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        rules={{ required: `logo is required` }}
                                                                        render={({ field }) => (
                                                                            <Button
                                                                                {...field}
                                                                                onChange={(e) =>
                                                                                    handleFileUpload(e, "image", "imageName", "imageUrl")
                                                                                }
                                                                                component="label"
                                                                                startIcon={<CameraAltIcon />}
                                                                            >
                                                                                <VisuallyHiddenInput type="file" />
                                                                            </Button>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <FormHelperText className="text-danger">
                                                                    {errors.imageName && errors.imageName.errorMessage}
                                                                </FormHelperText>
                                                            </div>

                                                            <div className="img-btn-div">
                                                                <Button type="submit" sx={{ marginLeft: "10px" }}>
                                                                    <DoneOutlineIcon
                                                                        sx={{ fontSize: "16px", cursor: "pointer" }}
                                                                    />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <span>
                                                            {" "}
                                                            <img
                                                                className="user-brandlogo img-fluid"
                                                                src={
                                                                    formData.imageName
                                                                        ? formData.imageName
                                                                        : "/images/user.png"
                                                                }
                                                                alt="logo"
                                                            />
                                                            {editBtn === true && (
                                                                <EditIcon
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        cursor: "pointer",
                                                                        marginLeft: "5px",
                                                                    }}
                                                                    onClick={() => handleEditBtn("imageName")}
                                                                />
                                                            )}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="brand-details ml-3">
                                                    <h4 className="font-weight-bold">
                                                        {editKey === "name" ? (
                                                            <div className="d-inline-block">
                                                                <div className="d-flex align-items-start">
                                                                    <div>
                                                                        <Controller
                                                                            name="name"
                                                                            control={control}
                                                                            defaultValue=""
                                                                            rules={{ required: " Name is required " }}
                                                                            render={({ field }) => (
                                                                                <input
                                                                                    placeholder="Enter name"
                                                                                    type="text"
                                                                                    {...field}
                                                                                    className="sml-inp"
                                                                                />
                                                                            )}
                                                                        />
                                                                        <FormHelperText className="text-danger">
                                                                            {errors.name && errors.name.message}
                                                                        </FormHelperText>
                                                                    </div>
                                                                    <Button type="submit">
                                                                        <DoneOutlineIcon
                                                                            sx={{ fontSize: "16px", cursor: "pointer" }}
                                                                        />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <span className="men-prof-name">
                                                                <h4 className="font-weight-bold">
                                                                    {formData.name}
                                                                    <span>
                                                                        <img
                                                                            src={"/images/verified-tick.png"}
                                                                            alt="verified"
                                                                        />
                                                                    </span>
                                                                </h4>
                                                                {editBtn === true && (
                                                                    <EditIcon
                                                                        sx={{
                                                                            fontSize: "14px",
                                                                            cursor: "pointer",
                                                                            marginLeft: "5px",
                                                                        }}
                                                                        onClick={() => handleEditBtn("name")}
                                                                    />
                                                                )}
                                                            </span>
                                                        )}
                                                    </h4>
                                                    <span style={{ fontSize: "12px", color: "gray" }}>
                                                        {formData.district}, {formData.state}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <div className="d-flex align-items-center">
                                                <div>
                                                    <img
                                                        className="user-cardlogo"
                                                        src={"/images/debitCrd.png"}
                                                        alt="bankCards"
                                                    />
                                                </div>
                                                <div className="brand-details ml-3">
                                                    <p className="par m-0">UID No.</p>

                                                    <span
                                                        style={{
                                                            color: "#020671",
                                                            fontSize: "16px",
                                                            fontWeight: "600",
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={handleClickOpen}
                                                    >
                                                        {formData.uid}
                                                    </span>
                                                </div>
                                            </div> */}
                                            <div>
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <div className="mr-2">
                                                        <span style={{ fontSize: "10px" }}>
                                                            Engagement Level :
                                                            <span
                                                                style={{
                                                                    color: "#020671",
                                                                    fontWeight: "600",
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                ACE
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: "10px" }}>
                                                            Member Since :
                                                            <span
                                                                style={{
                                                                    color: "#020671",
                                                                    fontWeight: "600",
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                {formData.memberSince}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="det-flex mt-3">
                                                    <div className="mobile-resp">
                                                        <span className="ml-2" style={{ fontSize: "14px" }}>
                                                            {editKey === "linkedIn" ? (
                                                                <div className="d-inline-block">
                                                                    <div className="d-flex align-items-start">
                                                                        <div>
                                                                            <FormControl sx={{ width: 150 }}>
                                                                                <Controller
                                                                                    name="linkedIn"
                                                                                    control={control}
                                                                                    defaultValue=""
                                                                                    // rules={{
                                                                                    //     value: linkedInPattern,
                                                                                    //     message: "Invalid LinkedIn profile URL"
                                                                                    // }}
                                                                                    render={({ field }) => (
                                                                                        <TextField
                                                                                            variant="outlined"
                                                                                            label="LinkedIn"
                                                                                            placeholder="Enter LinkedIn"
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            {...field}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <FormHelperText className="text-danger">
                                                                                    {errors.linkedIn && errors.linkedIn.message}
                                                                                </FormHelperText>
                                                                            </FormControl>
                                                                        </div>
                                                                        <Button type="submit">
                                                                            <DoneOutlineIcon
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <span>
                                                                    <img src="/images/linkedin-logo.png" />
                                                                    {/* {formData.linkedIn}{" "} */}
                                                                    {editBtn === true && (
                                                                        <EditIcon
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleEditBtn("linkedIn")
                                                                            }
                                                                        />
                                                                    )}
                                                                </span>
                                                            )}
                                                        </span>
                                                    </div>

                                                    <div className="mobile-resp">
                                                        <div>
                                                            <FaPhoneAlt
                                                                className="ml-2"
                                                                style={{
                                                                    color: "#020671",
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <span className="ml-2" style={{ fontSize: "14px" }}>
                                                                {editKey === "phone" ? (
                                                                    <div className="d-inline-block">
                                                                        <div className="d-flex align-items-start">
                                                                            <div>
                                                                                <FormControl sx={{ width: 150 }}>
                                                                                    <Controller
                                                                                        name="phone"
                                                                                        control={control}
                                                                                        defaultValue=""
                                                                                        rules={{
                                                                                            required: `Phone is required`,
                                                                                            maxLength: {
                                                                                                value: 10,
                                                                                                validate: validatePhoneNumber,
                                                                                            },
                                                                                            minLength: {
                                                                                                value: 10,
                                                                                                validate: validatePhoneNumber,
                                                                                            },
                                                                                        }}
                                                                                        render={({ field }) => (
                                                                                            <TextField
                                                                                                variant="outlined"
                                                                                                label="Phone Number *"
                                                                                                placeholder="Enter Phone Number"
                                                                                                fullWidth
                                                                                                type="text"
                                                                                                {...field}
                                                                                                onKeyDown={(e) =>
                                                                                                    validateNumberonly(e)
                                                                                                }
                                                                                                inputProps={{ maxLength: 10 }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                    <FormHelperText className="text-danger">
                                                                                        {errors.phone && errors.phone.message}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            </div>
                                                                            <Button type="submit">
                                                                                <DoneOutlineIcon
                                                                                    sx={{
                                                                                        fontSize: "16px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <span>
                                                                        {" "}
                                                                        {/* {formData.phone}{" "} */}
                                                                        {
                                                                            showViewbtn !== 0 ? (
                                                                                <span className="blur_css">+91 XXXXX-XXXXX</span>
                                                                            ) : (
                                                                                <span>+91 {formData.phone}</span>
                                                                            )
                                                                        }
                                                                        {editBtn === true && (
                                                                            <EditIcon
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleEditBtn("phone")
                                                                                }
                                                                            />
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="mobile-resp">
                                                        <div>
                                                            <MdOutlineMail
                                                                className="ml-2"
                                                                style={{
                                                                    color: "#020671",
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <span className="ml-2" style={{ fontSize: "14px" }}>
                                                                {/* {formData.email} */}
                                                                {
                                                                    showViewbtn !== 0 ? (
                                                                        <span className="blur_css ml-2" style={{ fontSize: "14px" }}>XXXXXXXXXXX</span>
                                                                    ) : (
                                                                        <span className="ml-2" style={{ fontSize: "14px" }}>{formData.email}</span>
                                                                    )
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="mobile-resp">
                                                        <div>
                                                            <CiGlobe
                                                                className="ml-2"
                                                                style={{
                                                                    color: "#020671",
                                                                }}
                                                            />
                                                        </div>

                                                        <div>
                                                            <span className="ml-2" style={{ fontSize: "14px" }}>
                                                                {editKey === "website" ? (
                                                                    <div className="d-inline-block">
                                                                        <div className="d-flex align-items-start">
                                                                            <div>
                                                                                <Controller
                                                                                    name="website"
                                                                                    control={control}
                                                                                    defaultValue=""
                                                                                    rules={{
                                                                                        required: `Website is required`,
                                                                                    }}
                                                                                    render={({ field }) => (
                                                                                        <input
                                                                                            placeholder="Enter Website"
                                                                                            type="text"
                                                                                            {...field}
                                                                                            className="sml-inp"
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <FormHelperText className="text-danger">
                                                                                    {errors.website &&
                                                                                        errors.website.message}
                                                                                </FormHelperText>
                                                                            </div>
                                                                            <Button type="submit">
                                                                                <DoneOutlineIcon
                                                                                    sx={{
                                                                                        fontSize: "16px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <span>
                                                                        {" "}
                                                                        {formData.website}{" "}
                                                                        {editBtn === true && (
                                                                            <EditIcon
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleEditBtn("website")
                                                                                }
                                                                            />
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {showViewbtn !== 0 && (
                                                    <div className="text-right mt-3">
                                                        <Button
                                                            variant="outlined"
                                                            className="edit-btn-profile"
                                                            sx={{
                                                                textTransform: "none",
                                                                border: "1px solid",
                                                                borderColor: "#020671",
                                                                color: "#020671",
                                                                padding: "6px 30px",
                                                            }}
                                                            onClick={handlemaskClickOpen}
                                                        >
                                                            View Contact
                                                        </Button>
                                                    </div>
                                                )}
                                                {userProfileId === 0 && (
                                                    <div className="profile-btn  mt-3">
                                                        {/* <div className="mr-3 edit-btn-res">
                                                            {editBtn === false && (
                                                                <Button
                                                                    variant="outlined"
                                                                    className="edit-btn-profile"
                                                                    sx={{
                                                                        textTransform: "none",
                                                                        border: "1px solid",
                                                                        borderColor: "#020671",
                                                                        color: "#020671",
                                                                        padding: "6px 20px",
                                                                    }}
                                                                    onClick={handleOpenEdit}
                                                                >
                                                                    Edit Profile
                                                                </Button>
                                                            )}
                                                            {editBtn === true && (
                                                                <Button
                                                                    variant="outlined"
                                                                    className="edit-btn-profile"
                                                                    sx={{
                                                                        textTransform: "none",
                                                                        border: "1px solid",
                                                                        borderColor: "#020671",
                                                                        color: "#020671",
                                                                        padding: "6px 20px",
                                                                    }}
                                                                    type="submit"
                                                                >
                                                                    Save Profile
                                                                </Button>
                                                            )}
                                                        </div> */}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-5">
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="flex-start"
                                            justifyContent="center"
                                            spacing={5}
                                        >
                                            <Grid item xs={12} sm={12}>
                                                <div className="mt-2">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-around"
                                                        alignItems="flex-start"
                                                        spacing={1}
                                                    >
                                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                            <div className="profile-box1">
                                                                <div>
                                                                    <h6 style={{ fontWeight: "600" }}>Key Highlights</h6>
                                                                    <ul className="p-0">
                                                                        {(
                                                                            roleval === 'INCUBATOR' ||
                                                                            roleval === 'ACCELERATOR' ||
                                                                            roleval === 'BANK' ||
                                                                            roleval === 'MEDIA-AGENCY' ||
                                                                            roleval === 'BLOGGER-INFLUENCER' ||
                                                                            roleval === 'NGO'
                                                                        ) && (
                                                                                <li className="d-flex align-items-start justify-content-start">
                                                                                    <div className="key-img mt-2">
                                                                                        <img src="/images/FocussedSector.png" alt="pieChart" />
                                                                                    </div>
                                                                                    <div className="ml-3">
                                                                                        <span className="list-span">Focussed Sector</span>
                                                                                        <p className="list-para">
                                                                                            {formData.focusedSector ? (
                                                                                                formData.focusedSector.map((item, index) => (
                                                                                                    <React.Fragment key={index}>
                                                                                                        {index > 0 && ", "}
                                                                                                        {item}
                                                                                                    </React.Fragment>
                                                                                                ))
                                                                                            ) : (
                                                                                                "Focused sectors not available"
                                                                                            )}
                                                                                        </p>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        {(
                                                                            roleval === 'INCUBATOR' ||
                                                                            roleval === 'ACCELERATOR' ||
                                                                            roleval === 'BANK' ||
                                                                            roleval === 'MEDIA-AGENCY' ||
                                                                            roleval === 'BLOGGER-INFLUENCER' ||
                                                                            roleval === 'NGO'
                                                                        ) && (
                                                                                <li className="d-flex align-items-start justify-content-start">
                                                                                    <div className="key-img mt-2">
                                                                                        <img src={"/images/PreferredStage.png"} alt="pieChart" />
                                                                                    </div>
                                                                                    <div className="ml-3">
                                                                                        <>
                                                                                            <span className="list-span">Preferred Stage</span>
                                                                                        </>
                                                                                        <>
                                                                                            <p className="list-para">

                                                                                                {formData.preferredStage ? (
                                                                                                    <>
                                                                                                        {formData.preferredStage.map((item, index) => (
                                                                                                            <React.Fragment key={index}>
                                                                                                                {index > 0 && ", "}
                                                                                                                {item}
                                                                                                            </React.Fragment>
                                                                                                        ))}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        -
                                                                                                    </>
                                                                                                )}
                                                                                            </p>
                                                                                        </>
                                                                                    </div>
                                                                                </li>)}
                                                                        {(roleval === 'INCUBATOR' || roleval === 'ACCELERATOR' || roleval === 'BANK' || roleval === 'MEDIA-AGENCY' ||
                                                                            roleval === 'BLOGGER-INFLUENCER' || roleval === 'NGO') && (
                                                                                <li className="d-flex align-items-start justify-content-start">
                                                                                    <div className="key-img mt-2">
                                                                                        <img src={"/images/NatureEntity.png"} alt="pieChart" />
                                                                                    </div>
                                                                                    <div className="ml-3">
                                                                                        <>
                                                                                            <span className="list-span">Nature of Entity</span>
                                                                                        </>
                                                                                        <>
                                                                                            <p className="list-para">{formData.natureOfEntity}</p>
                                                                                        </>
                                                                                    </div>
                                                                                </li>)}
                                                                        {(roleval === 'INCUBATOR' || roleval === 'ACCELERATOR' || roleval === 'BANK' || roleval === 'MEDIA-AGENCY' ||
                                                                            roleval === 'BLOGGER-INFLUENCER' || roleval === 'NGO') && (<li className="d-flex align-items-start justify-content-start">
                                                                                <div className="key-img mt-2">
                                                                                    <img src={"/images/AvailableFacilities.png"} alt="pieChart" />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <>
                                                                                        <span className="list-span">Available Facilities</span>
                                                                                    </>
                                                                                    <>
                                                                                        <p className="list-para">-</p>
                                                                                    </>
                                                                                </div>
                                                                            </li>)}
                                                                        {(roleval === 'ACCELARATOR' && (
                                                                            <li className="d-flex align-items-start justify-content-start">
                                                                                <div className="key-img mt-2">
                                                                                    <img
                                                                                        src={"/images/funding-ticket.png "}
                                                                                        alt="pieChart"
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <>
                                                                                        <span className="list-span">Fund Ticket Size</span>
                                                                                    </>
                                                                                    <>
                                                                                        <p className="list-para">
                                                                                            {" "}
                                                                                            {formData.fundingTicketSize ? formData.fundingTicketSize : '-'}
                                                                                        </p>
                                                                                    </>
                                                                                </div>
                                                                            </li>))}
                                                                        {(roleval === 'EDU-INST' && (
                                                                            <li className="d-flex align-items-start justify-content-start">
                                                                                <div className="key-img mt-2">
                                                                                    <img
                                                                                        src={"/images/avability.png"}
                                                                                        alt="pieChart"
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <>
                                                                                        <span className="list-span">Incubation availability</span>
                                                                                    </>
                                                                                    <>
                                                                                        <p className="list-para">
                                                                                            {" "}
                                                                                            {formData.available ? formData.available : '-'}
                                                                                        </p>
                                                                                    </>
                                                                                </div>
                                                                            </li>))}
                                                                        {(roleval === 'EDU-INST' && (
                                                                            <li className="d-flex align-items-start justify-content-start">
                                                                                <div className="key-img mt-2">
                                                                                    <img
                                                                                        src={"/images/incubation name.png "}
                                                                                        alt="pieChart"
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <>
                                                                                        <span className="list-span">Incubation Name</span>
                                                                                    </>
                                                                                    <>
                                                                                        <p className="list-para">
                                                                                            {" "}
                                                                                            {formData.incubationName ? formData.incubationName : '-'}
                                                                                        </p>
                                                                                    </>
                                                                                </div>
                                                                            </li>))}
                                                                        {(roleval === 'GOVT-AGENCY' && (
                                                                            <li className="d-flex align-items-start justify-content-start">
                                                                                <div className="key-img mt-2">
                                                                                    <img
                                                                                        src={"/images/incubation name.png "}
                                                                                        alt="pieChart"
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <>
                                                                                        <span className="list-span">Department</span>
                                                                                    </>
                                                                                    <>
                                                                                        <p className="list-para">
                                                                                            {" "}
                                                                                            {formData.department ? formData.department : '-'}
                                                                                        </p>
                                                                                    </>
                                                                                </div>
                                                                            </li>))}
                                                                        {(roleval === 'CORPORATE' && (
                                                                            <li className="d-flex align-items-start justify-content-start">
                                                                                <div className="key-img mt-2">
                                                                                    <img
                                                                                        src={"/images/incubation name.png "}
                                                                                        alt="pieChart"
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <>
                                                                                        <span className="list-span">Industry</span>
                                                                                    </>
                                                                                    <>
                                                                                        <p className="list-para">
                                                                                            {" "}
                                                                                            {formData.industry ? formData.industry : '-'}
                                                                                        </p>
                                                                                    </>
                                                                                </div>
                                                                            </li>))}
                                                                        <li className="d-flex align-items-start justify-content-start">
                                                                            <div className="key-img mt-2">
                                                                                <img
                                                                                    src={"/images/Year.png "}
                                                                                    alt="pieChart"
                                                                                />
                                                                            </div>
                                                                            <div className="ml-3">
                                                                                <>
                                                                                    <span className="list-span">Year of Registeration</span>
                                                                                </>
                                                                                <>
                                                                                    <p className="list-para">
                                                                                        {" "}
                                                                                        {formData.yearOfReg ? formData.yearOfReg : '-'}
                                                                                    </p>
                                                                                </>
                                                                            </div>
                                                                        </li>
                                                                        {(roleval === 'CORPORATE' && (
                                                                            <li className="d-flex align-items-start justify-content-start">
                                                                                <div className="key-img mt-2">
                                                                                    <img
                                                                                        src={"/images/incubation name.png "}
                                                                                        alt="pieChart"
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <>
                                                                                        <span className="list-span">S2C</span>
                                                                                    </>
                                                                                    <>
                                                                                        <p className="list-para">
                                                                                            {" "}
                                                                                            {formData.interestToConductS2c ? formData.interestToConductS2c : '-'}
                                                                                        </p>
                                                                                    </>
                                                                                </div>
                                                                            </li>))}
                                                                        {(roleval === 'CORPORATE' && (
                                                                            <li className="d-flex align-items-start justify-content-start">
                                                                                <div className="key-img mt-2">
                                                                                    <img
                                                                                        src={"/images/open-innovation-profile.png "}
                                                                                        alt="pieChart"
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <>
                                                                                        <span className="list-span">Open Innovation Challenges</span>
                                                                                    </>
                                                                                    <>
                                                                                        <p className="list-para">
                                                                                            {" "}
                                                                                            {formData.challenges ? formData.challenges : '-'}
                                                                                        </p>
                                                                                    </>
                                                                                </div>
                                                                            </li>))}
                                                                        {(roleval === 'CORPORATE' && (
                                                                            <li className="d-flex align-items-start justify-content-start">
                                                                                <div className="key-img mt-2">
                                                                                    <img
                                                                                        src={"/images/csr-fund-profile.png"}
                                                                                        alt="pieChart"
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <>
                                                                                        <span className="list-span">CSR Fund Allocation</span>
                                                                                    </>
                                                                                    <>
                                                                                        <p className="list-para">
                                                                                            {" "}
                                                                                            {formData.csrFund ? formData.csrFund : '-'}
                                                                                        </p>
                                                                                    </>
                                                                                </div>
                                                                            </li>))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                            <div className="profile-box2">
                                                                <div className="invest-edit-abt">
                                                                    <div>
                                                                        <h5>About</h5>
                                                                    </div>
                                                                    <div>
                                                                        {editKey === "about" ? (
                                                                            <div>
                                                                                {" "}
                                                                                <Button type="submit">
                                                                                    <DoneOutlineIcon
                                                                                        sx={{ fontSize: "16px", cursor: "pointer" }}
                                                                                    />
                                                                                </Button>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {editBtn === true && (
                                                                                    <EditIcon
                                                                                        sx={{
                                                                                            fontSize: "14px",
                                                                                            cursor: "pointer",
                                                                                            marginLeft: "5px",
                                                                                        }}
                                                                                        onClick={() => handleEditBtn("about")}
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {editKey === "about" ? (
                                                                    <div className="d-inline-block">
                                                                        <div className="d-flex align-items-start">
                                                                            <div>
                                                                                <Controller
                                                                                    name="about"
                                                                                    control={control}
                                                                                    defaultValue=""
                                                                                    rules={{ required: `Brief is required` }}
                                                                                    render={({ field }) => (
                                                                                        <textarea
                                                                                            type="text"
                                                                                            {...field}
                                                                                            className="invest-area"
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <FormHelperText className="text-danger">
                                                                                    {errors.about && errors.about.message}
                                                                                </FormHelperText>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <p className="para">
                                                                        {displayText}
                                                                        {isTruncated && (
                                                                            <span
                                                                                onClick={toggleReadMore}
                                                                                style={{ cursor: "pointer", color: "#7F9AD2" }}
                                                                            >
                                                                                {showFull ? " Read Less" : " Read More..."}
                                                                            </span>
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            {/* <div className="profile-box2 mt-2">
                                                                <h5 style={{ fontWeight: "600" }}>Point of Contact</h5>

                                                                <div style={{ width: "100%", overflowX: "auto" }}>
                                                                    <table
                                                                        className="table"
                                                                        style={{ width: "100%", borderCollapse: "collapse" }}
                                                                    >
                                                                        <tbody>
                                                                            {founder &&
                                                                                founder.map((val, index) => (
                                                                                    <tr key={index}>
                                                                                        <td
                                                                                            style={{ padding: "0.75rem", verticalAlign: "top" }}
                                                                                        >
                                                                                            <div className="d-flex align-items-center founder-profile-img">
                                                                                                <img
                                                                                                    src={val.profileUrl ? val.profileUrl : "/images/user.png"}
                                                                                                    alt="FOUNDER"
                                                                                                    className="img-fluid"
                                                                                                />
                                                                                                <div className="ml-3">
                                                                                                    <p className="list-para mb-0">{val.name}</p>

                                                                                                    <span
                                                                                                        style={{ fontSize: "10px", color: "#B3B3B3" }}
                                                                                                    >
                                                                                                        {" "}
                                                                                                        Founder & Partner
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td
                                                                                            style={{ padding: "0.75rem", verticalAlign: "top" }}
                                                                                        >
                                                                                            <div className="soc-logos">
                                                                                                <div>
                                                                                                    <a
                                                                                                        href={val.linkedin}
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                    >
                                                                                                        <img
                                                                                                            src="/images/X.png"
                                                                                                            alt="x-icon"
                                                                                                            className="linkedin-icn"
                                                                                                        />
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <a
                                                                                                        href={val.linkedin}
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                    >
                                                                                                        <img
                                                                                                            src="/images/linkedin-logo.png"
                                                                                                            alt="linkedin-icon"
                                                                                                            className="linkedin-icn"
                                                                                                        />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div> */}
                                                            {/* {(roleval === 'INCUBATOR' && (<div className="profile-box2 mt-3">
                                                                <div>
                                                                    <h5>Special recognitions </h5>
                                                                </div>
                                                                <div>
                                                                    <p>We are the India's First AI Incubator was officially launched by the Honorable Tamil Nadu Minister for Micro, Small, and Medium Enterprises, T. M. Anabarasan, alongside Archana Patnaik, Secretary to the Government for MSMEs.</p>
                                                                </div>

                                                            </div>))} */}
                                                            {/* {(roleval === 'INCUBATOR' || roleval === 'ACCELARATOR' && (
                                                                <div className="Services-box">
                                                                    <div>
                                                                        <h5>Portfolio Startups</h5>
                                                                    </div>
                                                                    <div>
                                                                        <Swiper
                                                                            slidesPerView={screenSize}
                                                                            spaceBetween={90}
                                                                            autoplay={{
                                                                                delay: 1000,
                                                                                disableOnInteraction: false,
                                                                            }}
                                                                            modules={[Autoplay]}
                                                                            className="mySwiper"
                                                                        >
                                                                            <SwiperSlide>
                                                                                <div className="sup-sm-crds-inv">
                                                                                    <div className="service-card-back-layers">
                                                                                        <div className="layer-crd-back-img-inv">
                                                                                            <div className="service-card-back-profss">
                                                                                                <img
                                                                                                    src="/images/GREENPOD_LOGO1 (1).png"
                                                                                                    alt="logo"
                                                                                                    className="smcrd-card-back-image"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="card-content-offers">
                                                                                            <span>50 Fin</span>
                                                                                        </div>
                                                                                        <div className="card-content-offers1">
                                                                                            <img src={"/images/Page-1.png"} alt="globe" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </SwiperSlide>

                                                                            <SwiperSlide>
                                                                                <div className="sup-sm-crds-inv ml-3 mb-3">
                                                                                    <div className="service-card-back-layers">
                                                                                        <div className="layer-crd-back-img-inv">
                                                                                            <div className="service-card-back-profss">
                                                                                                <img
                                                                                                    src="/images/GREENPOD_LOGO2.png"
                                                                                                    alt="logo"
                                                                                                    className="smcrd-card-back-image"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="card-content-offers">
                                                                                            <span>ab Coffee</span>
                                                                                        </div>
                                                                                        <div className="card-content-offers1">
                                                                                            <img src={"/images/Page-1.png"} alt="globe" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </SwiperSlide>
                                                                            <SwiperSlide>
                                                                                <div className="sup-sm-crds-inv ml-3 mb-3">
                                                                                    <div className="service-card-back-layers">
                                                                                        <div className="layer-crd-back-img-inv">
                                                                                            <div className="service-card-back-profss">
                                                                                                <img
                                                                                                    src="/images/GREENPOD_LOGO3.png"
                                                                                                    alt="logo"
                                                                                                    className="smcrd-card-back-image"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="card-content-offers">
                                                                                            <span>accio Robotics</span>
                                                                                        </div>
                                                                                        <div className="card-content-offers1">
                                                                                            <img src={"/images/Page-1.png"} alt="globe" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </SwiperSlide>
                                                                            <SwiperSlide>
                                                                                <div className="sup-sm-crds-inv ml-3 mb-3">
                                                                                    <div className="service-card-back-layers">
                                                                                        <div className="layer-crd-back-img-inv">
                                                                                            <div className="service-card-back-profss">
                                                                                                <img
                                                                                                    src="/images/GREENPOD_LOGO3.png"
                                                                                                    alt="logo"
                                                                                                    className="smcrd-card-back-image"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="card-content-offers">
                                                                                            <span>accio Robotics</span>
                                                                                        </div>
                                                                                        <div className="card-content-offers1">
                                                                                            <img src={"/images/Page-1.png"} alt="globe" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </SwiperSlide>
                                                                        </Swiper>
                                                                    </div>
                                                                </div>))} */}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* Qr-modal */}
                <Dialog
                    fullWidth={fullScreen}
                    maxWidth={'sm'}
                    open={qrModalopen}
                    onClose={handleModalClose}
                >
                    {/* <DialogTitle>Verify</DialogTitle> */}

                    <DialogContent>
                        <Grid
                            container
                            direction="row"
                            justifyContent="start"
                            alignItems="flex-start"
                            spacing={1}
                        >
                            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                                <div className="code-container">
                                    <h3 className="text-center">Your QR</h3>
                                    <img src={formData.qr} alt="qrcode" className="img-fluid" />
                                    {formData.qr ? <span className="download-button" onClick={downloadBarcode}>Download</span> : <></>}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                                <div className="code-container">
                                    <h3 className="text-center">Your Barcode</h3>
                                    <img src={formData.barcode} alt="Barcode" className="img-fluid" />
                                    {formData.barcode ? <span className="download-button" onClick={downloadBarcode}>Download</span> : <></>}
                                </div>
                            </Grid>
                        </Grid>

                    </DialogContent>
                </Dialog>
                <div>
                    <MaskDialog />
                </div>
            </div>

        </>

    );
};

export default EnablersProfilePage