import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Button,

  Divider,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";

const Mediafilter = ({
  setSearchInputValue,
  setCategoryId,
  type,
  seachLableName,
  setSelectionRange,
  endDate,
  setEndDate,
  startDate,
  setStartDate,
  selectionRange,
  setDepId,
  setZoneId,
  setZoneName
}) => {
  const [formData, setFormData] = useState({});
  const { handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData, mode: 'onBlur' });

  const [open, setOpen] = useState(true);
  const [opens, setOpens] = useState(true);
  const [store, setStore] = useState([]);
  useDidMountEffect(() => {
    listApi();
  }, [startDate, endDate]);

  const [checked, setChecked] = useState(false);
  const handleCheck = (event, id) => {
    var val = event.target.checked;
    setChecked(val === true ? id : "");
    setCategoryId(val === true ? id : "");
  };
  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };

  // useDidMountEffect(() => {
  //   listApi();
  // }, [searchInputValue,categoryId]);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClicks = () => {
    setOpens(!opens);
  };

  const listApi = () => {
    apiService(`home/category/list?type=${type}`, "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.categories) {
          setStore(result.data.categories);
        }
      })
      .catch((err) => { });
  };
  useDidMountEffect(() => {
    departmentdropdownList()
    hubdropdownList()
  }, []);
  const [departmentList, setDepartmentList] = useState([]);
  const departmentdropdownList = () => {
    apiService(`home/department/list`, '', "get")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data) {
              var values = result.data;
              setDepartmentList(values);
            }
          }
        }
      })
      .catch((err) => { });
  };

  const [hubedList, setHubedList] = useState([]);
  const hubdropdownList = () => {
    apiService(`hub/min/list`, "", "post")
      .then((result) => {
        if (result && result.data) {
          setHubedList(result.data.hubs);
        }
      })
      .catch((err) => {
      });
  };

  const handleSelectDepartment = (value) => {
    const dep = departmentList.filter(val => val.name === value);
    if (value !== null) {
      setDepId(dep[0].id)
    } else {
      setDepId("")
    }

    setValue(`department`, value);
    clearErrors(`department`);
  };

  const handleSelectHublist = (selectedDisplayName) => {
    setZoneName(selectedDisplayName)
    const selectedHub = hubedList.find(hub => hub.displayName === selectedDisplayName);
    if (selectedDisplayName !== null) {
      setZoneId(selectedHub.id)
    } else {
      setZoneId("")
      setZoneName("")
    }

    setValue("zone", selectedDisplayName);
    clearErrors("zone", "");
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const handleApply = () => {
    if (selectionRange.startDate && selectionRange.endDate) {
      const formattedStartDate = dayjs(selectionRange.startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(selectionRange.endDate).format("YYYY-MM-DD");

      if (formattedStartDate === formattedEndDate) {

        setStartDate(formattedStartDate);
        setEndDate("");
      } else {

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
      }
    }
  };
  // const handleApply = () => {
  //   if (selectionRange.startDate && selectionRange.endDate) {
  //     const formattedStartDate = dayjs(selectionRange.startDate).format(
  //       "YYYY-MM-DD"
  //     );
  //     const formattedEndDate = dayjs(selectionRange.endDate).format(
  //       "YYYY-MM-DD"
  //     );

  //     setStartDate(formattedStartDate);
  //     setEndDate(formattedEndDate);
  //   }
  // };


  const handleClear = () => {
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setStartDate("");
    setEndDate("");
  };

  const handleNextSevenDays = () => {
    const today = new Date();
    const nextSevenDays = new Date(today);
    nextSevenDays.setDate(today.getDate() + 7);

    setSelectionRange({
      startDate: today,
      endDate: nextSevenDays,
      key: "selection",
    });
  };

  return (
    <div className="filter">
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          padding: "25px",
          borderRadius: "15px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <h6
            component="div"
            id="nested-list-subheader"
            style={{ color: "lightgray" }}
          >
            Filters
          </h6>
        }
      >
        <div className="filter-search">
          <TextField
            fullWidth
            type="search"
            placeholder={seachLableName}
            onChange={handleSearch}
          />
        </div>
        <div className="filter-search">
          <Autocomplete
            disablePortal           
            options={departmentList.map(
              (option) => option.name
            )}
            onChange={(event, newValue) =>
              handleSelectDepartment(newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Department"
                placeholder="Select Department"
              />
            )}
            fullWidth
            margin="normal"
          />
        </div>
        <div className="filter-search">
          <Autocomplete
            disablePortal            
            options={hubedList.map((option) => option.displayName)}
            onChange={(event, newValue) => handleSelectHublist(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Zone"
                placeholder="Select Zone"
              />
            )}
            fullWidth
            margin="normal"
          />
        </div>

        {/* <div className="mb-2">
          <ListItemButton
            onClick={handleClick}
            sx={{
              background: "#020671",
              color: "#fff",
              borderRadius: "5px",
              "&:hover": {
                background: "#020671",
                color: "#fff",
              },
            }}
          >
            <ListItemText primary="Categories" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ marginTop: "5px" }}>
              {store &&
                store.map((a, i) => (
                  <div key={i}>
                    <div>
                      <ul className="filter-lists">
                        <li style={{ display: "none" }}>{a.id}</li>
                        <li>
                          {" "}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked === a.id ? true : false}
                                onChange={(e) => handleCheck(e, a.id)}
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  "&.Mui-checked": { color: "#020671" },
                                }}
                              />
                            }
                          />
                        </li>
                        <li style={{ fontSize: "12px" }}>{a.category}</li>
                      </ul>
                    </div>
                  </div>
                ))}
            </List>
          </Collapse>
        </div> */}
        <div>
          <ListItemButton
            onClick={handleClicks}
            sx={{
              background: "#020671",
              color: "#fff",
              borderRadius: "5px",
              "&:hover": {
                background: "#020671",
                color: "#fff",
              },
            }}
          >
            <ListItemText primary="Date" />
            {opens ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={opens} timeout="auto" unmountOnExit>
            <div>
              <div className="next-btn-div">
                <span
                  onClick={handleNextSevenDays}

                  className="next-seven-btn"
                >
                  Next 7 days
                </span>
              </div>
              <div className="date-div">
                <DateRange
                  onChange={handleSelect}
                  ranges={[selectionRange]}
                  rangeColors={["#FFB525"]}
                  className="calender-divs"
                />
                <Divider />
                <div className="act-btn">
                  <Button
                    onClick={handleClear}

                    size="small"
                    className="clear-date"
                  >
                    Clear
                  </Button>

                  <Button
                    onClick={handleApply}
                    variant="contained"
                    size="small"
                    className="appl-btn"
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </List>
    </div>
  );
};

export default Mediafilter;
