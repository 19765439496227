import React from 'react'
import Navbar from '../navbar-page/Navbar'
import Footer from '../footer-page/Footer'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'

const PrivacyPolicy = () => {
    return (
        <>
            <section className="termscond-page">
                <Navbar />
                <div className="back-color">
                <div className="back-layer"></div>
                <div className="back-layer1"></div>

                <h1 className='terms-heading mb-3'>Privacy Policy</h1>

                <div className='term-box'>
                    <div className='container'>
                        <h4 className='blck-clr pt-5'>SECTION 1 – WHAT DO WE DO WITH YOUR INFORMATION?</h4>
                        <p className='privacypadding'>
                            When you signup with the OneTN.in portal, as part of the buying and selling process, we collect the personal
                            information you give us such as your name, address, and email address.
                        </p>
                        <p className='privacypadding'>
                            When you browse the OneTN.in portal, we also automatically receive your computer’s internet protocol (IP)
                            address in order to provide us with information that helps us learn about your browser and operating system.
                        </p>
                        <p className='privacypadding'>
                            Email marketing: With your permission, we may send you emails about our deals, new schemes, and other ecosystem
                            updates.
                        </p>



                        <h4 className='blck-clr'>SECTION 2 – CONSENT</h4>
                        <p className='privacypadding-head'>How do you get my consent?</p>
                        <p className='privacypadding'>
                            When you provide us with personal information to complete registration, verify your mobile number, claim a deal,
                            we imply that you consent to our collecting it and using it for that specific reason only.
                        </p>
                        <p className='privacypadding'>
                            If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for
                            your expressed consent or provide you with an opportunity to say no.
                        </p>

                        <p className='privacypadding-head'>How do I withdraw my consent?</p>
                        <p className='privacypadding'>
                            If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection,
                            use or disclosure of your information, at any time, by contacting us at <Link style={{ color: '#2b7dc7' }} to='' >tech@startuptn.in</Link>
                        </p>


                        <h4 className='blck-clr '>SECTION 3 – DISCLOSURE</h4>
                        <p className='privacypadding'>
                            We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
                        </p>



                        <h4 className='blck-clr '>SECTION 4 – PAYMENT</h4>
                        <p className='privacypadding'>
                            We use 3rd party approved vendors for processing payments. We do not store your card data on their servers.
                            The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment.
                            Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After
                            that is complete, your purchase transaction information is not saved.
                        </p>
                        <p className='privacypadding'>
                            Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council,
                            which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
                        </p>
                        <p className='privacypadding'>
                            PCI-DSS requirements help ensure the secure handling of credit card information by OneTN.in portal and
                            its service providers.
                        </p>



                        <h4 className='blck-clr '>SECTION 5 – THIRD-PARTY SERVICES</h4>
                        <p className='privacypadding'>
                            In general, the third-party providers used by us will only collect, use and disclose your information to the extent
                            necessary to allow them to perform the services they provide to us.
                        </p>
                        <p className='privacypadding'>
                            However, certain third-party service providers, such as payment gateways and other payment transaction processors,
                            have their own privacy policies in respect to the information we are required to provide to them for your
                            purchase-related transactions.
                        </p>
                        <p className='privacypadding'>
                            For these providers, we recommend that you read their privacy policies so you can understand the manner in which
                            your personal information will be handled by these providers.
                        </p>
                        <p className='privacypadding'>
                            In particular, remember that certain providers may be located in or have facilities that are located a different
                            jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a
                            third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which
                            that service provider or its facilities are located.
                        </p>
                        <p className='privacypadding'>
                            Once you leave OneTN.in portal or are redirected to a third-party website or application, you are no longer
                            governed by this Privacy Policy or our website’s Terms of Service.
                        </p>



                        <h4 className='blck-clr '>Links</h4>
                        <p className='privacypadding'>
                            When you click on links on OneTN.in portal, they may direct you away from our site. We are not responsible for the
                            privacy practices of other sites and encourage you to read their privacy statements.
                        </p>



                        <h4 className='blck-clr '>SECTION 6 – SECURITY</h4>
                        <p className='privacypadding'>
                            To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it
                            is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
                        </p>


                        <h4 className='blck-clr '>SECTION 7 – COOKIES</h4>
                        <p className='privacypadding'>
                            We use cookies to maintain session of your user. It is not used to personally identify you on other websites.
                        </p>



                        <h4 className='blck-clr '>SECTION 8 – AGE OF CONSENT</h4>
                        <p className='privacypadding'>
                            By using this site, you represent that you are at least the age of majority in your state or province of residence,
                            or that you are the age of majority in your state or province of residence and you have given us your consent to
                            allow any of your minor dependents to use this site.
                        </p>



                        <h4 className='blck-clr '>SECTION 9 – CHANGES TO THIS PRIVACY POLICY</h4>
                        <p className='privacypadding'>
                            We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and
                            clarifications will take effect immediately upon their posting on the website. If we make material changes to
                            this policy, we will notify you here that it has been updated, so that you are aware of what information we collect,
                            how we use it, and under what circumstances, if any, we use and/or disclose it.
                        </p>

                        <p className='privacypadding-head'>QUESTIONS AND CONTACT INFORMATION</p>
                        <p className='privacypadding'>
                            If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint,
                            or simply want more information contact our Privacy Compliance Officer at  <Link style={{ color: '#2b7dc7' }} to='' >tech@startuptn.in</Link>
                        </p>
                    </div>

                    <div className='container py-3'>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Grid item sm={12} md={6} lg={6}>
                                <p className='m-0'>
                                    Manager, TAMILNADU STARTUP AND INNOVATION MISSION
                                </p>
                                <p className='m-0'>
                                    D3-10, 3rd FLOOR, IIT MADRAS RESEARCH PARK
                                </p>
                                <p className='m-0'>
                                    KANAGAM ROAD, TARAMANI
                                </p>
                                <p className='m-0'>
                                    CHENNAI
                                </p>
                                <p className='m-0'>
                                    Chennai – 600113
                                </p>
                                <p className='m-0'>
                                    Tamil Nadu – India
                                </p>
                            </Grid>
                            <Grid item sm={12} md={6} lg={6}>

                            </Grid>
                        </Grid>
                    </div>
                </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default PrivacyPolicy