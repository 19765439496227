import React from 'react'

const Norecordsfound = ({ name }) => {
    return (
        <div className='text-center w-100 m-5 nodatacss'>
            <h5 className='mb-4'>{name}</h5>
            <div>
                <img src="/images/tamilnadu_logo_high.svg" alt="tamilnadu_logo_high.svg" className='logo_dash img-fluid' />
            </div>
            <div className='mt-5'>
                <h3 className='title_p opa'>
                    {/* StartupTN 3.0 */}
                    OneTN
                </h3>
            </div>
        </div>
    )
}

export default Norecordsfound