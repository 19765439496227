import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  // dev & qa code
  // apiKey: "AIzaSyB5iDtv2WuPHXioyoHOZGDdMzTVtFb8O-o",
  // authDomain: "tnstartup-ecosystem-dev.firebaseapp.com",
  // projectId: "tnstartup-ecosystem-dev",
  // storageBucket: "tnstartup-ecosystem-dev.appspot.com",
  // messagingSenderId: "372913154338",
  // appId: "1:372913154338:web:fea50ecdd54fff944ddd7b",
  // measurementId: "G-0REMSP12F7"

  // live code
  apiKey: "AIzaSyCe8pOFTT5PIbRuaXpF63mrVUeX30Gajq0",
  authDomain: "startuptn-52e1b.firebaseapp.com",
  projectId: "startuptn-52e1b",
  storageBucket: "startuptn-52e1b.appspot.com",
  messagingSenderId: "421461953919",
  appId: "1:421461953919:web:dedd9a6f93dd105b839e4a",
  measurementId: "G-LPEJ951CK3",
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const firestore = app.firestore();

// Create Google Auth Provider instance
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// Create Facebook Auth Provider instance
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export { auth, firestore, googleAuthProvider, facebookAuthProvider };
export default app;
