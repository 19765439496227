import {
  Dialog,
  DialogContent,
  DialogContentText,
  Pagination,
  Stack,
  Grid,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Eventregister from "./Eventregister";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DataContext from "../../../../context/DataContext";
import Mediafilter from "../Mediafilter";
import Norecordsfound from "../../../common-pages/Norecordsfound";
const Eventlist = () => {
  const { navigator, setPageLoading, fullScreen } = useContext(DataContext);
  const [opens, setOpens] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [depId, setDepId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [zoneName, setZoneName] = useState("");
  useDidMountEffect(() => {
    listApi();
  }, [page, searchInputValue, categoryId, startDate, endDate, depId, zoneId]);
  const [eventList, setEventList] = useState([]);
  const listApi = () => {
    setEventList([]);
    setPageLoading(true);
    var req = {
      searchString: searchInputValue,
      categoryId: categoryId,
      listSize: 12,
      pageNumber: page,
      fromDate: startDate,
      toDate: endDate,
      departmentId: depId,
      zoneId: zoneId,
      // displayName:zoneName
    };
    apiService(`event/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          if (result.data.event) {
            setEventList(result.data.event);
            setTotalPage(result.data.totalPages);
          }
        }
      })
      .catch((err) => {});
  };
  const [regCateId, setRegCateId] = useState("");
  // const handleRegister = (val) => {
  //   var token = localStorage.getItem("jwttoken");
  //   if (token === "") {
  //     navigator("/login");
  //   } else if (token === null) {
  //     navigator("/login");
  //   } else {
  //     setRegCateId(val);
  //     setOpens(true);
  //   }
  // };
  const handleRegister = (val, url) => {
    debugger;
    apiService(`event/view/count?id=${val}`, "", "unauthput");
    debugger;
    if (url !== undefined) {
      debugger;
      window.open(url, "_blank");
    }
  };
  const handleReportClose = () => {
    setOpens(false);
    listApi();
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  return (
    <>
      <div className="event-title">
        <h2 className="event-titles">Events</h2>
        <div className="event-line">
          <img src="/images/event-line.png" alt="" />
        </div>
      </div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Grid item sm={12} md={3} lg={3}>
          <Mediafilter
            endDate={endDate}
            setEndDate={setEndDate}
            startDate={startDate}
            setStartDate={setStartDate}
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            setSearchInputValue={setSearchInputValue}
            setCategoryId={setCategoryId}
            type={"event"}
            seachLableName={"Enter Event Name"}
            setDepId={setDepId}
            setZoneId={setZoneId}
            setZoneName={setZoneName}
          />
        </Grid>
        <Grid item sm={12} md={9} lg={9}>
          <div className="container-fluid">
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              {eventList.length > 0 ? (
                eventList.map((item, i) => (
                  <Grid item xs={12} sm={12} md={12} lg={4} key={i}>
                    <div className="event-list">
                      <div
                        class="card"
                        style={{
                          width: "18rem",
                          minHeight: "380px",
                          borderRadius: "10px",
                        }}
                      >
                        <div className="event-img">
                          <img
                            src={
                              item.imageName
                                ? item.imageName
                                : "/images/oneTn/white-image.png"
                            }
                            class="card-img-top"
                            alt=""
                          />
                          {item.category && (
                            <div className="img-top">
                              <span>{item.category ? item.category : ""}</span>
                            </div>
                          )}

                          <div className="event-time-top">
                            <div className="home-event-days">
                              {item.startDay && (
                                <>
                                  <div className="time-bg">
                                    <h6 className="time-color">
                                      {item.startDay}
                                    </h6>
                                    <span>{item.startMonth}</span>
                                  </div>
                                </>
                              )}
                              {item.endDay && (
                                <>
                                  <div className="time-bg ml-2">
                                    <h6 className="time-color">
                                      {item.endDay}
                                    </h6>
                                    <span>{item.endMonth}</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          class="card-body"
                          style={{ padding: "10px", position: "relative" }}
                        >
                          <div className="event-details">
                            <h5>{item.title}</h5>
                            <h6 className="event-time mt-2">
                              <img
                                src="/images/clock-icon.png"
                                alt=""
                                className="mr-2"
                              />
                              {item.formattedTime}
                              {/* - <span className="event-free">({item.meetSpace})</span> */}
                            </h6>
                            {item.location ? (
                              <span className="event-location">
                                <img
                                  src="/images/location-icon.png"
                                  alt=""
                                  className="mr-2"
                                />
                                {item.location}
                              </span>
                            ) : (
                              // <span className="event-location">&nbsp;</span>
                              <span className="event-location">
                                <img
                                  src="/images/online-symbal.png"
                                  alt=""
                                  className="mr-2"
                                />
                                Online
                              </span>
                            )}

                            <div className="reg-fees2 mt-2">
                              {item.paid === false ? (
                                <span className="event-free">Free</span>
                              ) : (
                                <span className="event-free">
                                  ₹{item.amount}
                                </span>
                              )}
                              {item.showRegisterButton === true && (
                                <div>
                                  <button
                                    className="reg-btn"
                                    onClick={() =>
                                      handleRegister(item.id, item.url)
                                    }
                                  >
                                    Register
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))
              ) : (
                <>
                  <Norecordsfound name="Events Not Found" />
                </>
              )}
            </Grid>
            {totalPage > 1 ? (
              <div className="event-pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Grid>
      </Grid>
      <Dialog fullScreen={fullScreen} maxWidth={"lg"} open={opens}>
        <DialogContent sx={{ minWidth: "800px" }}>
          <div>
            <span
              style={{ color: "#2260FF", cursor: "pointer" }}
              onClick={handleReportClose}
            >
              <ArrowBackIcon /> Back
            </span>
          </div>
          <Eventregister
            categoryId={regCateId}
            setOpens={setOpens}
            via={"home"}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Eventlist;
