import { Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Feets = () => {
    return (
        <div>
            <div className="event-title">
                <h2 className="event-titles">Feeds</h2>
                <div className="event-line">
                    <img src="/images/event-line.png" alt="" />
                </div>
            </div>
            <div className='p-lg-5'>
            <Grid
                container
                direction="row"
                alignItems="start"
                justifyContent="start"
                spacing={2}
            >
                <Grid item sm={12} md={8} lg={8}>
                    <div className="feet-box">
                        <table
                            className="table"
                            style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                            <tbody>
                                    <div className='feets-box-1'>
                                <tr>
                                    <td style={{ padding: "0.75rem", verticalAlign: "top", width: "60px" }}>
                                        <div className='Feeds-logo-img'>
                                            <img
                                                src="/images/man.png"
                                                alt=""
                                                className="img-fluid "
                                            />
                                        </div>
                                    </td>
                                    <td style={{ padding: "0.75rem", verticalAlign: "top" }}>
                                        <div className="news-letter-details">
                                            <div className="d-flex">
                                                <div >
                                                    <h5><b>Sivarajah Ramanathan</b></h5>
                                                    <img src="/images/verified-tick.png" alt="" />
                                                    <span>@startuptn .</span>
                                                    <span>1 min</span>
                                                    <p className='mb-2'>I am thrilled to share the exciting news that Tamil Nadu has been recognized as the 'Best Performer' in the Startup India Rankings for the year 2022. This prestigious accolade, announced on January 16, 2024, is a monumental achievement for our state and a true testament to the vibrant and dynamic startup ecosystem we have collectively built.
                                                       <small>https://www.startupindia.gov.in/srf/portal/SRF_2022_Result_page/Tamil_Nadu.pdf</small></p>

                                                        <div>
                                                            <img src="/images/message.png" alt="" className='mr-2'/>
                                                            <span  >3</span>
                                                            <img className='ml-4 mr-2' src="/images/heart.png" alt="" />
                                                            <span clasName="ml-2">16</span>
                                                        </div>

                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="bloggings-tabled" style={{ padding: "0.75rem", verticalAlign: "top",  }}>
                                        <div className="bloggings-dates">
                                            <h5 className="ml-auto feets-dot">...</h5>
                                        </div>
                                    </td>
                                    <div className="grid-border"></div>
                                </tr>
                                </div>
                            </tbody>

                        </table>


                        <table
                            className="table"
                            style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                            <tbody>
                                    <div className='feets-box-1'>
                                <tr>
                                    <td style={{ padding: "0.75rem", verticalAlign: "top", width: "60px" }}>
                                        <div className='Feeds-logo-img'>
                                            <img
                                                src="/images/nan-muthalvan.png"
                                                alt=""
                                                className="img-fluid "
                                            />
                                        </div>
                                    </td>
                                    <td style={{ padding: "0.75rem", verticalAlign: "top" }}>
                                        <div className="news-letter-details">
                                            <div className="d-flex">
                                                <div >
                                                    <h5><b>J. Innocent Divya, IAS</b></h5>
                                                    <img src="/images/verified-tick.png" alt="" />
                                                    <span>@TNSDC .</span>
                                                    <span>5 min</span>
                                                    <p className='mb-2'>Niral Thiruvizha 2023 is an annual Event for college students organized at State level through 7 regions and 38 Districts.
                                                       <small>Apply Now</small></p>

                                                        <div>
                                                            <img src="/images/nan.png" alt=""  class="img-fluid"/>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <img src="/images/message.png" alt="" className='mr-2'/>
                                                            <span  >3</span>
                                                            <img className='ml-5' src="/images/retweet.png" alt="" />
                                                            <img className='ml-5 mr-2' src="/images/heart.png" alt="" />
                                                            <span clasName="ml-2">16</span>
                                                            <img className='ml-5' src="/images/share.png" alt="" />
                                                        </div>

                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="bloggings-tabled" style={{ padding: "0.75rem", verticalAlign: "top" }}>
                                        <div className="bloggings-dates">
                                            <h5 className="ml-auto feets-dot">...</h5>
                                        </div>
                                    </td>
                                    <div className="grid-border"></div>
                                </tr>
                                </div>
                            </tbody>

                        </table>
                        <table
                            className="table"
                            style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                            <tbody>
                                    <div className='feets-box-1'>
                                <tr>
                                    <td style={{ padding: "0.75rem", verticalAlign: "top", width: "60px" }}>
                                        <div className='Feeds-logo-img'>
                                            <img
                                                src="/images/man.png"
                                                alt=""
                                                className="img-fluid "
                                            />
                                        </div>
                                    </td>
                                    <td style={{ padding: "0.75rem", verticalAlign: "top" }}>
                                        <div className="news-letter-details">
                                            <div className="d-flex">
                                                <div >
                                                    <h5><b>Sivarajah Ramanathan</b></h5>
                                                    <img src="/images/verified-tick.png" alt="" />
                                                    <span>@startuptn .</span>
                                                    <span>1 min</span>
                                                    <p className='mb-2'>I am thrilled to share the exciting news that Tamil Nadu has been recognized as the 'Best Performer' in the Startup India Rankings for the year 2022. This prestigious accolade, announced on January 16, 2024, is a monumental achievement for our state and a true testament to the vibrant and dynamic startup ecosystem we have collectively built.
                                                       <small>https://www.startupindia.gov.in/srf/portal/SRF_2022_Result_page/Tamil_Nadu.pdf</small></p>

                                                        <div>
                                                            <img src="/images/message.png" alt="" className='mr-2'/>
                                                            <span  >3</span>
                                                            <img className='ml-4 mr-2' src="/images/heart.png" alt="" />
                                                            <span clasName="ml-2">16</span>
                                                        </div>

                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="bloggings-tabled" style={{ padding: "0.75rem", verticalAlign: "top" }}>
                                        <div className="bloggings-dates">
                                            <h5 className="ml-auto feets-dot">...</h5>
                                        </div>
                                    </td>
                                    <div className="grid-border"></div>
                                </tr>
                                </div>
                            </tbody>

                        </table>
                    </div>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                    <div className="news-box">
                        <h4><b>What’s New</b></h4>
                        <div className='news-feeds-flex'>
                            <div>
                                <img src="/images/news-logo-1.png" alt="" />
                                <span><b>EDII .</b></span>
                                <span>25 Aug</span>
                                <p>Training Skill programs for New Age Entrepreneurs</p>
                            </div>
                            <div>
                                <img src="/images/news-1.png" alt="" />
                            </div>
                        </div>
                        <div className='news-feeds-flex'>
                            <div>
                                <img src="/images/news-logo-2.png" alt="" />
                                <span><b>StartupTN .</b></span>
                                <span>25 Aug</span><br />
                                <span className='feeds-links'>#Startup Thiruvizha 2024</span>
                                <p>Training Skill programs for New Age Entrepreneurs</p>
                            </div>
                            <div>
                                <img src="/images/news-2.png" alt="" />
                            </div>
                        </div>
                        <div className='news-feeds-flex'>
                            <div>
                                <img src="/images/news-logo-1.png" alt="" />
                                <span><b>EDII .</b></span>
                                <span>25 Aug</span>
                                <p>Training Skill programs for New Age Entrepreneurs</p>
                            </div>
                            <div>
                                <img src="/images/news-1.png" alt="" />
                            </div>
                        </div>
                        <div className='news-feeds-flex'>
                            <div>
                                <img src="/images/news-logo-2.png" alt="" />
                                <span><b>StartupTN .</b></span>
                                <span>25 Aug</span><br />
                                <span className='feeds-links'>#Startup Thiruvizha 2024</span>
                                <p>Training Skill programs for New Age Entrepreneurs</p>
                            </div>
                            <div>
                                <img src="/images/news-2.png" alt="" />
                            </div>
                        </div>
                        <div className='feeds-more'>
                        <span>Show More</span>
                        </div>
                    </div>
                </Grid>
            </Grid>
            </div>
        </div>
    )
}

export default Feets
