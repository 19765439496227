import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Container, Grid, Tabs } from "@mui/material";
import { Typewriter } from "react-simple-typewriter";
import Mediafilter from "./Mediafilter";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import Eventlist from "./Event-Page/Eventlist";
import DataContext from "../../../context/DataContext";
import MediaGallery from "./Media-page/MediaGallery";
import Latestnewspage from "./Latest-News-Page/Latestnewspage";
import Newsletter from "./News-Letter-Page/Newsletter";
import Galleryvideo from "./Gallery-Video-Page/Galleryvideo";
import Blog from "./Blog-Page/Blog";
import Feets from "./Feets-Page/Feets";

const Mediatap = () => {

  const [value, setValue] = useState("2");
  const [tabName, setTabName] = useState("Events");
  const [buttonText, setButtonText] = useState("Explore");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const tabNames = {
      1:"Feeds",
      2: "Events",
      3: "Gallery",
      4: "Newsletters",
      5: "Blogs",
      6: "Newsletters",
      7: "Gallery Videos",
    };
    const buttonTexts = {
      1: "Apply Now",
      2: "Apply Now",
      3: "Explore",
      4: "Read News",
      5: "Register",
      6: "Subscribe",
      7: "Watch Videos",
    };
    if (tabNames[newValue] && buttonTexts[newValue]) {
      setTabName(tabNames[newValue]);
      setButtonText(buttonTexts[newValue]);
    }
  };
  const firstLetter = tabName.charAt(0);
  const remainingLetters = tabName.slice(1);

  const handleType = (count) => {
    // console.log(count);
  };

  const handleDone = () => {
    console.log(`Done after 5 loops!`);
  };
  return (
    <div className="back-color">
      <div className="back-layer"></div>
      <div className="back-layer1"></div>
      {/* <div className="top-layer"></div> */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Container>
            <div className="main-title">
              <span className="small-tit">CONNECT,LEARN, SUCCEED </span>

              <p className="big-title mb-0">Don't Miss Out On</p>
              <div className="vectorimg">
                <img src={"/images/Vector (1).png"} alt="vector" />
              </div>
              <p className="big-title2 mb-0 ">
                OneTN
                <span className="big-title2 ml-2">
                  Startup
                  <div className="anim-div">
                    <div className="typewriter-container">
                      <div className="anim-abs">
                        <span>{buttonText}</span>
                      </div>
                      <div className="chipTextml-1">
                        <span>{firstLetter}</span>
                        <Typewriter
                          words={[remainingLetters]}
                          loop={false}
                          cursor
                          cursorStyle={<span className="cursor-style"></span>}
                          cursorBlinking={false}
                          typeSpeed={250}
                          deleteSpeed={60}
                          delaySpeed={1000}
                          onLoopDone={handleDone}
                          onType={handleType}
                          style={{
                            background: "#B6CBFB",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </span>
              </p>
              <p className="titles-paras">
                Stay connected and informed with the latest happenings in the
                startup ecosystem. Our events and media resources are designed
                to inspire, educate, and empower entrepreneurs, innovators, and
                business leaders.
              </p>
            </div>
          </Container>
        </Grid>
      </Grid>
      <TabContext value={value} className="tab-div">
        <div className="name-tab">
          <Box className="tabs-boxs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label="Feeds"
                value="1"
                style={{
                  backgroundColor: value === "1" ? "#083fb2" : "",
                  color: value === "1" ? "white" : "#000",
                }}
                className="setFont"
              />
              <Tab
                label="Events"
                value="2"
                style={{
                  backgroundColor: value === "2" ? "#083fb2" : "",
                  color: value === "2" ? "white" : "#000",
                }}
                className="setFont"
              />
              <Tab
                label="Media Gallery"
                value="3"
                style={{
                  backgroundColor: value === "3" ? "#083fb2" : "",
                  color: value === "3" ? "white" : "#000",
                }}
                className="setFont"
              />

              <Tab
                label="Latest News & Updates"
                value="4"
                style={{
                  backgroundColor: value === "4" ? "#083fb2" : "",
                  color: value === "4" ? "white" : "#000",
                }}
                className="setFont"
              />
              <Tab
                label="Blogs"
                value="5"
                style={{
                  backgroundColor: value === "5" ? "#083fb2" : "",
                  color: value === "5" ? "white" : "#000",
                }}
                className="setFont"
              />
              <Tab
                label="Newsletters"
                value="6"
                style={{
                  backgroundColor: value === "6" ? "#083fb2" : "",
                  color: value === "6" ? "white" : "#000",
                }}
                className="setFont"
              />
              <Tab
                label="Gallery Videos"
                value="7"
                style={{
                  backgroundColor: value === "7" ? "#083fb2" : "",
                  color: value === "7" ? "white" : "#000",
                }}
                className="setFont"
              />
            </Tabs>
          </Box>
        </div>
        <TabPanel value="1" sx={{background:'#eef2f8'}}>
          <Feets />
        </TabPanel>
        <TabPanel value="2">
          <Eventlist />
        </TabPanel>
        {/* <TabPanel value="2">
           <div className="event-title">
          <h2 className="event-titles">Events</h2>
          <div className="event-line">
            <img src="/images/event-line.png" alt="" />
          </div>
        </div>
          <Grid container direction="row" alignItems="flex-start" justifyContent="center" >
            <Grid item sm={12} md={3} lg={3}><Mediafilter searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue} setCategoryId={setCategoryId}/></Grid>
            <Grid item sm={12} md={9} lg={9}><Eventlist eventList={eventList} eventdataList={eventdataList} /></Grid>
          </Grid>
        </TabPanel> */}
        <TabPanel value="3">
          <MediaGallery />
        </TabPanel>
        <TabPanel value="4">
          <Latestnewspage />
        </TabPanel>
        <TabPanel value="5">
          <Blog />
        </TabPanel>
        <TabPanel value="6">
          <Newsletter />
        </TabPanel>
        <TabPanel value="7">
          <Galleryvideo />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default Mediatap;
