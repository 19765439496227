import React, { useContext, useState } from "react";
import { Grid, Pagination, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";

const ProjectList = () => {
  const { navigator, setPageLoading, hasPrivilege } = useContext(DataContext);
  const [page, setPage] = useState(1);
  useDidMountEffect(() => {
    listApi()
  }, [page])

  const [totalPage, setTotalPage] = useState(1);
  const [meetingList, setMeetingList] = useState([]);

  const listApi = () => {
    setPageLoading(true)
    var data = {
      listSize: 12,
      pageNumber: page,
    };
    apiService("project/list", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (result.data.projects) {
            setMeetingList(result.data.projects);
          }

        }
      })
      .catch((err) => {
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleAddProject = () => {
    navigator("/admin/project/add");
  };

  const handleDetailsView = (id) => {
    navigator(`/admin/project/add/${btoa(id)}`);
   
  };
  return (
    <div className="department-add-crd mt-3">
      <div className="depCrd-title mb-3">
        <div className="text-dep">
          <h6>Projects</h6>
          <span>See your Project information</span>
        </div>
        <div className="dep-add-act">
          {
            hasPrivilege("47") && (
              <button
                className="dep-add-act-btn mr-5"
                onClick={() => handleAddProject()}
              >
               + Add Project
              </button>
             )
          } 
        </div>
      </div>
      <div className="added-card">
        <div className="">
          {meetingList.length > 0 ? (
            <div className="">
              <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={4}>
              {
                meetingList.map((item, i) => (
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="card_sec1">
                    <Card className="card-comp-adm" key={i}>
                      <div className="imag-border">
                        <img
                          className="comp-logo"
                          src={item.imageName ? item.imageName : '/images/oneTn/onetn_fav.png'}
                          alt="company-logo"
                        />
                      </div>
                      <CardContent>
                        <p className="admcrd-para-color">{item.name}</p>
                      </CardContent>
                      <div className="view det text-center">
                        <button
                          className="depart-view-btn"
                          onClick={() => handleDetailsView(item.id)}
                        >
                          View Details
                        </button>
                      </div>
                    </Card>
                  </div>
                  </Grid>
                ))
              }
              </Grid>
            </div>
          ) : (
            <div className='text-center nodatacss_admin'>
              <h5 className='mb-4'>Department data not found</h5>
              <div>
                <img src="/images/oneTn/black_oneTn.png" alt="blue horizontal.png" className='img-fluid' />
              </div>
            </div>
          )
          }
          {
            meetingList.length > 0 && totalPage > 1 && (
              <div className="event-pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            )
          }
        </div>

      </div>
    </div >
  );
};

export default ProjectList;
