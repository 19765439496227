import { Autocomplete, FormHelperText, Grid, InputAdornment, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import DataContext from '../../../../context/DataContext'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import apiService from '../../../../api/apiService'
import notifyService from '../../../../api/notifySerivce'
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS'
import cryptoJS from '../../../../hooks/cryptoJS'

const BasicinfoEcosystem = () => {
  const { navigator, validateEmail, validateNumberonly, validatePhoneNumber, setPageLoading, handleNextClick, isWhitespace, validatePANNumber,checkUppernumeric } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, formState: { errors }, } = useForm({ values: formData });
  const roleval = localStorage.getItem('role')
  useDidMountEffect(() => {
    handleNextClick(50)
    if (id !== null) {
      getApi()
    }
  }, [])
  var id = localStorage.getItem("id");
  var type = localStorage.getItem('type')
  const getApi = () => {
    setPageLoading(true)
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data
          if (data.phone) {
            data.phone = cryptodecryptJS(data.phone)
          }
          if (data.email) {
            data.email = cryptodecryptJS(data.email)
          }
          if (data.pan) {
            data.pan = cryptodecryptJS(data.pan)
            // setPanTickIcon(1)
          }
          setFormData(data)
        }
      }).catch((err) => {
      });

  }
  const onSubmit = (data) => {
    data.persona = type
    data.userId = id
    data.phone = cryptoJS(data.phone)
    data.email = cryptoJS(data.email.toLowerCase())
    data.pan = cryptoJS(data.pan.toUpperCase());
    apiService('userprofile/save', data, 'post').then((result) => {
      if (result.data.responseStatus === 'Success') {
        navigator('/ecosystem/address')
      }
    }).catch((err) => {
    });
  };
  const onError = (event) => {
    console.log('error Data:', event);
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className='signup-data_div'>
            <div className='forms-title d-flex'>
              <p>Organization Info {`>`} <span className='path_sapn'>Basic Info</span></p>
              <p className='ml-auto'><span className='count_num'>1</span>/4</p>
            </div>
            <div className='signup_box_div mt-4'>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  {
                    (roleval === 'INCUBATOR' || roleval === 'ACCELARATOR') ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mt-3'>
                          <Controller
                            name="organisationName"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Organization Name is required" }}
                            rules={{
                              required: 'Organization Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Organization Name*"
                                placeholder="Enter Organization Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.organisationName && errors.organisationName)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.organisationName && errors.organisationName.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='my-3'>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Chief Executive Officer Name is required" }}
                            rules={{
                              required: 'Chief Executive Officer is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Chief Executive Officer Name*"
                                placeholder="Enter Chief Executive Officer Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.name && errors.name)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.name && errors.name.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{ required: "CEO Mail ID is required", validate: validateEmail }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="CEO Mail ID*"
                                placeholder="Enter CEO Mail ID"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.email && errors.email)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.email && errors.email.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{ required: "CEO Phone Number is required", validate: validatePhoneNumber }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="CEO Phone Number*"
                                placeholder="Enter CEO Phone Number"
                                fullWidth
                                type="text"
                                {...field}
                                onKeyDown={(e) => validateNumberonly(e)}
                                inputProps={
                                  { maxLength: 10 }
                                }
                                error={Boolean(errors.phone && errors.phone)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.phone && errors.phone.message}
                          </FormHelperText>
                        </Grid>
                      </>
                    ) : (<></>)
                  }
                  {
                    roleval === 'EDU-INST' ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mt-3'>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Institution Name is required" }}
                            rules={{
                              required: 'Institution Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Institution Name*"
                                placeholder="Enter Institution Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.name && errors.name)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.name && errors.name.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='my-3'>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Institution Mail ID is required", validate: validateEmail }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Institution Mail ID*"
                                placeholder="Enter Institution Mail ID"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.email && errors.email)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.email && errors.email.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="chairPerson"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Chairperson / Principal name is required" }}
                            rules={{
                              required: 'Chairperson / Principal Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Chairperson / Principal*"
                                placeholder="Enter Chairperson / Principal"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.chairPerson && errors.chairPerson)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.chairPerson && errors.chairPerson.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="chairPersonPhoneNo"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Chairperson Phone Number is required", validate: validatePhoneNumber }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Chairperson Phone Number*"
                                placeholder="Enter Chairperson Phone Number*"
                                fullWidth
                                type="text"
                                {...field}
                                onKeyDown={(e) => validateNumberonly(e)}
                                inputProps={
                                  { maxLength: 10 }
                                }
                                error={Boolean(errors.chairPersonPhoneNo && errors.chairPersonPhoneNo)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.chairPersonPhoneNo && errors.chairPersonPhoneNo.message}
                          </FormHelperText>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  {
                    roleval === 'BUSINESS-SOCIAL-FORM' ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='my-3'>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Forum Name is required" }}
                            rules={{
                              required: 'Forum Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Forum Name*"
                                placeholder="Enter Forum Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.name && errors.name)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.name && errors.name.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="head"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Forum Head is required" }}
                            rules={{
                              required: 'Forum Head is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Forum Head*"
                                placeholder="Enter Forum Head"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.head && errors.head)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.head && errors.head.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Forum Mail ID is required", validate: validateEmail }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Forum Mail ID*"
                                placeholder="Enter Forum Mail ID"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.email && errors.email)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.email && errors.email.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Forum Phone Number is required", validate: validatePhoneNumber }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Forum Phone Number*"
                                placeholder="Enter Forum Phone Number*"
                                fullWidth
                                type="text"
                                {...field}
                                onKeyDown={(e) => validateNumberonly(e)}
                                inputProps={
                                  { maxLength: 10 }
                                }
                                error={Boolean(errors.phone && errors.phone)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.phone && errors.phone.message}
                          </FormHelperText>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  {
                    roleval === 'GOVT-AGENCY' ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="agencyorpsuName"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Agency/PSU Name is required" }}
                            rules={{
                              required: 'Agency/PSU is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Agency/PSU Name*"
                                placeholder="Enter Agency/PSU Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.agencyorpsuName && errors.agencyorpsuName)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.agencyorpsuName && errors.agencyorpsuName.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="parentDepartment"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Parent Department is required" }}
                            rules={{
                              required: 'Parent Department is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Parent Department*"
                                placeholder="Enter Parent Department"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.parentDepartment && errors.parentDepartment)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.parentDepartment && errors.parentDepartment.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="chairPerson"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Chairperson is required" }}
                            rules={{
                              required: 'Chairperson is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Chairperson*"
                                placeholder="Enter Chairperson"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.chairPerson && errors.chairPerson)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.chairPerson && errors.chairPerson.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Phone Number is required", validate: validatePhoneNumber }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Phone Number*"
                                placeholder="Enter Phone Number"
                                fullWidth
                                type="text"
                                {...field}
                                onKeyDown={(e) => validateNumberonly(e)}
                                inputProps={
                                  { maxLength: 10 }
                                }
                                error={Boolean(errors.phone && errors.phone)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.phone && errors.phone.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Mail Address is required", validate: validateEmail }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Mail Address*"
                                placeholder="Enter Mail Address"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.email && errors.email)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.email && errors.email.message}
                          </FormHelperText>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  {
                    roleval === 'CORPORATE' ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='my-3'>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Corporate Name is required" }}
                            rules={{
                              required: 'Corporate Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Corporate Name*"
                                placeholder="Enter Corporate Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.name && errors.name)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.name && errors.name.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="keyPersonName"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Keyperson Name is required" }}
                            rules={{
                              required: 'Keyperson Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Keyperson Name*"
                                placeholder="Enter Keyperson Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.keyPersonName && errors.keyPersonName)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.keyPersonName && errors.keyPersonName.message}
                          </FormHelperText>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  {
                    roleval === 'BANK' ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='my-3'>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Bank Name is required" }}
                            rules={{
                              required: 'Bank Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Bank Name*"
                                placeholder="Enter Bank Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.name && errors.name)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.name && errors.name.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="keyPersonName"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Keyperson Name is required" }}
                            rules={{
                              required: 'Keyperson Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Keyperson Name*"
                                placeholder="Enter Keyperson Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.keyPersonName && errors.keyPersonName)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.keyPersonName && errors.keyPersonName.message}
                          </FormHelperText>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  {
                    (roleval === 'CORPORATE' || roleval === 'BANK') ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="keyPersonEmail"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Keyperson Mail ID is required", validate: validateEmail }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Keyperson Mail ID*"
                                placeholder="Enter Keyperson Mail ID"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.keyPersonEmail && errors.keyPersonEmail)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.keyPersonEmail && errors.keyPersonEmail.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Phone Number is required", validate: validatePhoneNumber }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Phone Number*"
                                placeholder="Enter Phone Number*"
                                fullWidth
                                type="text"
                                {...field}
                                onKeyDown={(e) => validateNumberonly(e)}
                                inputProps={
                                  { maxLength: 10 }
                                }
                                error={Boolean(errors.phone && errors.phone)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.phone && errors.phone.message}
                          </FormHelperText>
                        </Grid>
                      </>
                    ) : (
                      <>
                      </>
                    )
                  }
                  {
                    (roleval === 'EDU-INST' || roleval === 'BUSINESS-SOCIAL-FORM') ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="keyPersonName"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Keyperson Name is required" }}
                            rules={{
                              required: 'Keyperson Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Keyperson Name*"
                                placeholder="Enter Keyperson Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.keyPersonName && errors.keyPersonName)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.keyPersonName && errors.keyPersonName.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="keyPersonEmail"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Keyperson Mail ID is required", validate: validateEmail }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Keyperson Mail ID*"
                                placeholder="Enter Keyperson Mail ID"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.keyPersonEmail && errors.keyPersonEmail)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.keyPersonEmail && errors.keyPersonEmail.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="keyPersonPhone"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Keyperson Phone Number is required", validate: validatePhoneNumber }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Keyperson Phone Number*"
                                placeholder="Enter Keyperson Phone Number*"
                                fullWidth
                                type="text"
                                {...field}
                                onKeyDown={(e) => validateNumberonly(e)}
                                inputProps={
                                  { maxLength: 10 }
                                }
                                error={Boolean(errors.keyPersonPhone && errors.keyPersonPhone)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.keyPersonPhone && errors.keyPersonPhone.message}
                          </FormHelperText>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  {
                    (roleval === 'MEDIA-AGENCY' || roleval === 'BLOGGER-INFLUENCER' || roleval === 'NGO') ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Organization Name is required" }}
                            rules={{
                              required: 'Organization Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Organization Name*"
                                placeholder="Enter Organization Name"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.name && errors.name)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.name && errors.name.message}
                          </FormHelperText>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )
                  }
<Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="pan"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "PAN No. is required",
                        // validate: validatePANNumber
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="PAN No. *"
                          placeholder="Enter PAN No."
                          fullWidth
                          type="text"
                          // inputProps={{ maxLength: 10 }}
                          // onKeyDown={(e) => checkUppernumeric(e)}
                          // onInput={(e) => {
                          //   handlePanChange(e);
                          // }}
                          {...field}
                          error={Boolean(errors.pan && errors.pan)}
                        // InputProps={{
                        //   endAdornment: panTickIcon === 1 ? (
                        //     <InputAdornment position="end">
                        //       <CheckCircleIcon color="success" />
                        //     </InputAdornment>
                        //   ) : panTickIcon === 2 ? (
                        //     <InputAdornment position="end">
                        //       <CloseIcon color="error" />
                        //     </InputAdornment>
                        //   ) : null,
                        // }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.pan && errors.pan.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='text-center'>
                    <button className='next-btn' type="submit">Next</button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default BasicinfoEcosystem